import React, { useEffect, useState } from "react";
import styled, { Keyframes, keyframes } from "styled-components";
import earthImg from "../../Images/earth.gif";
import humanImg1 from "../../Images/walk_1.gif";
import humanImg2 from "../../Images/walk_2.gif";
import humanImg4 from "../../Images/walk_4.gif";
import text from "../../text-data";

interface StyledProps {
  deg?: number;
  bgColor?: string;
  percent?: number;
  showLog?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
`;

const Guage = styled.div`
  position: relative;
  width: 216px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 24px;
  overflow: hidden;
`;

const GuagePercent = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.percent}%;
  height: 100%;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#b4b4b4")};
  transition: width 1s ease-in-out, background-color 1s ease-in-out;
`;

const TemperLabel = styled.div`
  position: relative;
  font-size: 24px;
`;

const DiseaseLabel = styled.div`
  position: relative;
  font-size: 24px;
`;

const updateValueFrames = keyframes`
  0%{
    opacity: 0;
    color: red;
  }100%{
    opacity: 1;
    color: black;
  }
`;

const PointSpan = styled.span`
  animation: ${updateValueFrames} 0.5s ease-in-out;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 384px;
  height: 384px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const EarthImg = styled.img`
  width: calc(384px - 96px);
`;

const rotateFrame = (initDeg: number): Keyframes => {
  return keyframes`
    0%{
      transform: rotateZ(${initDeg}deg);
    }
    50%{
      transform: rotateZ(${initDeg + 180}deg);
    }
    100%{
      transform: rotateZ(${initDeg + 360}deg)
    }
  `;
};

const HumanImg = styled.div<StyledProps>`
  position: absolute;
  width: 75px;
  height: 378px;
  animation: ${(props) => rotateFrame(props.deg ? props.deg : 0)} 30s linear
    infinite;
  img {
    width: 75px;
  }
`;

const GenerationInfo = styled.div`
  font-size: 24px;
`;

const LogBox = styled.div<StyledProps>`
  position: absolute;
  width: 400px;
  min-height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dotted black;
  border-radius: 8px;
  background-color: white;
  opacity: ${(props) => (props.showLog ? 1 : 0)};
  transition: opacity 0.3s ease-in;
  padding: 16px 32px;
  line-height: 24px;
`;

interface Props {
  earth: Earth | undefined;
  gameOver: (causeOfDeath: CauseOfDeath) => void;
  earthLogQueue: Array<Log>;
  popEarthLogQueue: () => void;
  lan: Language;
}

const Earth: React.FunctionComponent<Props> = ({
  earth,
  earthLogQueue,
  popEarthLogQueue,
  lan,
}: Props) => {
  const [degree, setDegree] = useState<Array<number>>([300, 60, 180]);
  const [logContent, setLogContent] = useState<string>();
  const [showLog, setShowLog] = useState<boolean>(false);
  const [cleanLog, setCleanLog] = useState<boolean>(false);
  const [flowing, setFlowing] = useState<boolean>(false);

  useEffect(() => {
    if (earthLogQueue.length > 0 && !logContent) {
      setLogContent(earthLogQueue[0].content);
    }
  }, [earthLogQueue, logContent]);

  useEffect(() => {
    if (logContent) {
      setTimeout(() => {
        setShowLog(true);
      }, 500);
    }
  }, [logContent]);

  useEffect(() => {
    if (showLog) {
      setFlowing(true);
      setTimeout(() => {
        setShowLog(false);
      }, 5000);
    } else if (flowing) {
      setTimeout(() => {
        setCleanLog(true);
      }, 500);
    }
  }, [showLog, logContent, flowing]);

  useEffect(() => {
    if (cleanLog) {
      popEarthLogQueue();
      setLogContent(undefined);
      setCleanLog(false);
      setFlowing(false);
    }
  }, [cleanLog]);

  return (
    <Wrapper>
      {earth && (
        <Content>
          <Guage>
            <GuagePercent
              percent={(earth.temperature / 6) * 100}
              bgColor={`rgba(255, 0, 0, ${earth.temperature / 6})`}
            />
            <TemperLabel>
              {text[lan].earth.temperature(earth.temperature)}
            </TemperLabel>
          </Guage>
          <Guage>
            <GuagePercent
              percent={earth.disease}
              bgColor={`rgba(255, 0, 0, ${earth.disease / 100})`}
            />
            <DiseaseLabel>
              {text[lan].earth.disease(earth.disease)}
            </DiseaseLabel>
          </Guage>
          <ImageWrapper>
            <EarthImg src={earthImg} />
            <HumanImg deg={degree[0]}>
              <img src={humanImg1} />
            </HumanImg>
            <HumanImg deg={degree[1]}>
              <img src={humanImg2} />
            </HumanImg>
            <HumanImg deg={degree[2]}>
              <img src={humanImg4} />
            </HumanImg>
            {logContent && <LogBox showLog={showLog}>{logContent}</LogBox>}
          </ImageWrapper>
          <GenerationInfo>
            {text[lan].earth.generation(earth.generation)}
          </GenerationInfo>
        </Content>
      )}
    </Wrapper>
  );
};

export default Earth;
