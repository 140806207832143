import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import useEarth from "../../Hooks/useEarth";
import useSettings from "../../Hooks/useSettings";

type sortedMessageType = {
  id: string;
  content: {
    [lan in Language]: string;
  };
  weight: number;
};

type sortedMessagesType = {
  [key in Food | "lightoff" | "lighton"]: Array<sortedMessageType>;
};

const updateValueFrames = keyframes`
  0%{
    opacity: 0;
    color: red;
  }100%{
    opacity: 1;
    color: black;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  gap: 8px;
  overflow: scroll;
  padding-bottom: 32px;
`;

const TitleRow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  gap: 8px;
`;

const Row = styled.div`
  width: 100%;
  min-height: 40px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 8px;
`;

const BoldLabel = styled.div`
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const Label = styled.div`
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Value = styled.div`
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${updateValueFrames} 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: pink;
  }
`;

const MultiLineRow = styled.div`
  width: 1200px;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LongValue = styled.div`
  width: 1200px;
  min-height: 60px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${updateValueFrames} 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: pink;
  }
`;

const God: React.FunctionComponent = () => {
  const { earth, changeDisease, changeTemperature } = useEarth();
  const { settings, updateSettings, messages, addMessage, removeMessage } =
    useSettings();

  const [id, setId] = useState<string>("");
  const [generation, setGeneration] = useState<number>(0);
  const [temperature, setTemperature] = useState<number>(0);
  const [disease, setDisease] = useState<number>(0);
  const [viewSettings, setViewSettings] = useState<Settings>();
  const [sortedMessages, setSortedMessages] = useState<sortedMessagesType>();

  useEffect(() => {
    if (earth) {
      setId(earth.id);
      setGeneration(earth.generation);
      setTemperature(earth.temperature);
      setDisease(earth.disease);
    }
  }, [earth]);

  useEffect(() => {
    if (settings) {
      setViewSettings(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (viewSettings) {
      const newSortedMessages: sortedMessagesType = {
        meat: [],
        meal: [],
        fish: [],
        egg: [],
        insect: [],
        lightoff: [],
        lighton: [],
      };

      if (viewSettings.messages_lan) {
        Object.entries(viewSettings.messages_lan).forEach(
          ([id, value], idx) => {
            const { category, content, weight } = value;
            newSortedMessages[category].push({ id, content, weight });
          }
        );
      }
      setSortedMessages(newSortedMessages);
    }
  }, [viewSettings]);

  function handleTemperature(e: any) {
    const prevValue = e.target.innerText;
    const inputElm = document.createElement("input");
    inputElm.value = e.target.innerHTML;
    inputElm.focus();
    inputElm.onblur = () => {
      e.target.removeChild(inputElm);
      if (isNaN(Number(inputElm.value))) {
        // No Change
        e.target.innerText = Number(prevValue);
      } else {
        // Update
        const newValue = Number(inputElm.value);
        changeTemperature(newValue);
        e.target.innerText = Number(inputElm.value);
      }
    };
    inputElm.onkeydown = (evt: any) => {
      if (evt.code === "Enter") {
        evt.target.blur();
      }
    };
    e.target.innerText = "";
    e.target.appendChild(inputElm);
  }

  function handleDisease(e: any) {
    const prevValue = e.target.innerText;
    const inputElm = document.createElement("input");
    inputElm.value = e.target.innerHTML;
    inputElm.focus();
    inputElm.onblur = () => {
      e.target.removeChild(inputElm);
      if (isNaN(Number(inputElm.value))) {
        // No Change
        e.target.innerText = Number(prevValue);
      } else {
        // Update
        const newValue = Number(inputElm.value);
        changeDisease(newValue);
        e.target.innerText = Number(inputElm.value);
      }
    };
    inputElm.onkeydown = (evt: any) => {
      if (evt.code === "Enter") {
        evt.target.blur();
      }
    };
    e.target.innerText = "";
    e.target.appendChild(inputElm);
  }

  function handleUpdateSettings(e: any) {
    const prevValue = e.target.innerText;
    const inputElm = document.createElement("input");
    inputElm.value = e.target.innerHTML;
    inputElm.focus();
    inputElm.onblur = () => {
      e.target.removeChild(inputElm);
      if (isNaN(Number(inputElm.value))) {
        // No Change
        e.target.innerText = Number(prevValue);
      } else {
        // Update
        const [ref, key] = e.target.dataset["ref"].split(/\/(?=[^/]+$)/g);
        const newValue = Number(inputElm.value);
        updateSettings(ref, {
          [key]: newValue,
        });
        e.target.innerText = Number(inputElm.value);
      }
    };
    inputElm.onkeydown = (evt: any) => {
      if (evt.code === "Enter") {
        evt.target.blur();
      }
    };
    e.target.innerText = "";
    e.target.appendChild(inputElm);
  }

  function handleUpdateMessages(e: any, attribute: string) {
    const prevValue = e.target.innerText;
    const inputElm = document.createElement("input");
    inputElm.style.width = "100%";
    inputElm.value = e.target.innerHTML;
    inputElm.focus();
    inputElm.onblur = () => {
      e.target.removeChild(inputElm);
      if (
        attribute === "category" &&
        [
          "meat",
          "meal",
          "fish",
          "insect",
          "egg",
          "lighton",
          "lightoff",
        ].includes(inputElm.value)
      ) {
        // Update
        const newValue = inputElm.value;
        const messageId = e.target.dataset["messageid"];
        updateSettings(`messages_lan/${messageId}`, {
          category: newValue,
        });
        e.target.innerText = inputElm.value;
      } else if (attribute === "weight" && !isNaN(Number(inputElm.value))) {
        // Update
        const newValue = Number(inputElm.value);
        const messageId = e.target.dataset["messageid"];
        updateSettings(`messages_lan/${messageId}`, {
          weight: newValue,
        });
        e.target.innerText = Number(inputElm.value);
      } else if (attribute === "content") {
        // Update
        const newValue = inputElm.value;
        const messageId = e.target.dataset["messageid"];
        const lan = e.target.dataset["lan"];
        updateSettings(`messages_lan/${messageId}/content`, {
          [lan]: newValue,
        });
        e.target.innerText = inputElm.value;
      } else {
        // No Change
        e.target.innerText = prevValue;
      }
    };
    inputElm.onkeydown = (evt: any) => {
      if (evt.code === "Enter") {
        evt.target.blur();
      }
    };
    e.target.innerText = "";
    e.target.appendChild(inputElm);
  }

  function handleRemoveMessage(id: string) {
    removeMessage(id);
  }

  function convertLabelEngToKor(label: string) {
    const eng = [
      "fish",
      "insect",
      "meat",
      "meal",
      "egg",
      "lightoff",
      "lighton",
    ];
    const kor = ["생선", "곤충", "가축", "곡식", "닭", "불끄기", "불켜기"];

    const idx = eng.findIndex((v) => v === label);
    return kor[idx];
  }

  return (
    <Wrapper>
      <TitleRow>GOD</TitleRow>
      <TitleRow>
        <Label>{generation}th Earth</Label>
        <Label>온도(C&#176;)</Label>
        <Value onDoubleClick={handleTemperature}>{temperature}</Value>
        <Label>질병률(%)</Label>
        <Value onDoubleClick={handleDisease}>{disease}</Value>
        <Label />
        <Label />
      </TitleRow>
      {viewSettings && (
        <>
          <Row>
            <BoldLabel>월드</BoldLabel>
          </Row>
          <Row>
            <Label />
            <Label>라이프 변화량</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`world/human/life`}
            >
              {viewSettings.world.human.life}
            </Value>
            <Label>라이프 감소 간격(초)</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`world/timeDistance`}
            >
              {viewSettings.world.timeDistance}
            </Value>
            <Label />
            <Label />
          </Row>
          <Row />
          <Row>
            <BoldLabel>음식</BoldLabel>
          </Row>
          <Row>
            <BoldLabel>고기</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/meat/life`}
            >
              {viewSettings.food.meat.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/meat/temperature`}
            >
              {viewSettings.food.meat.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/meat/disease`}
            >
              {viewSettings.food.meat.disease}
            </Value>
          </Row>
          <Row>
            <BoldLabel>달걀</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/egg/life`}
            >
              {viewSettings.food.egg.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/egg/temperature`}
            >
              {viewSettings.food.egg.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/egg/disease`}
            >
              {viewSettings.food.egg.disease}
            </Value>
          </Row>
          <Row>
            <BoldLabel>물고기</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/fish/life`}
            >
              {viewSettings.food.fish.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/fish/temperature`}
            >
              {viewSettings.food.fish.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/fish/disease`}
            >
              {viewSettings.food.fish.disease}
            </Value>
          </Row>
          <Row>
            <BoldLabel>곡식</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/meal/life`}
            >
              {viewSettings.food.meal.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/meal/temperature`}
            >
              {viewSettings.food.meal.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/meal/disease`}
            >
              {viewSettings.food.meal.disease}
            </Value>
          </Row>
          <Row>
            <BoldLabel>곤충</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/insect/life`}
            >
              {viewSettings.food.insect.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/insect/temperature`}
            >
              {viewSettings.food.insect.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`food/insect/disease`}
            >
              {viewSettings.food.insect.disease}
            </Value>
          </Row>
          <Row />
          <Row>
            <BoldLabel>기타</BoldLabel>
          </Row>
          <Row>
            <BoldLabel>불켜기</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`etc/lighton/life`}
            >
              {viewSettings.etc.lighton.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`etc/lighton/temperature`}
            >
              {viewSettings.etc.lighton.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`etc/lighton/disease`}
            >
              {viewSettings.etc.lighton.disease}
            </Value>
          </Row>
          <Row>
            <BoldLabel>불끄기</BoldLabel>
            <Label>라이프</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`etc/lightoff/life`}
            >
              {viewSettings.etc.lightoff.life}
            </Value>
            <Label>온도</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`etc/lightoff/temperature`}
            >
              {viewSettings.etc.lightoff.temperature}
            </Value>
            <Label>질병률</Label>
            <Value
              onDoubleClick={handleUpdateSettings}
              data-ref={`etc/lightoff/disease`}
            >
              {viewSettings.etc.lightoff.disease}
            </Value>
          </Row>
        </>
      )}
      {sortedMessages &&
        Object.entries(sortedMessages).map(([category, sMsg], idx) => {
          return (
            <React.Fragment key={idx}>
              <Row />
              <Row>
                <Label />
                <Label />
                <BoldLabel>메세지 - {convertLabelEngToKor(category)}</BoldLabel>
                <Label />
                <Value
                  onClick={() => {
                    const payload: Message = {
                      category: category as Food | "lightoff" | "lighton",
                      content: {
                        ko: `새로운 ${convertLabelEngToKor(category)} 텍스트`,
                        en: `새로운 ${convertLabelEngToKor(category)} 텍스트`,
                      },
                      weight: 1,
                    };
                    addMessage(payload);
                  }}
                >
                  새 텍스트 추가
                </Value>
              </Row>
              {sMsg.map((msg, idxidx) => {
                return (
                  <Row key={idxidx}>
                    <Value
                      onDoubleClick={(e) => {
                        handleUpdateMessages(e, "weight");
                      }}
                      data-messageid={msg.id}
                    >
                      {msg.weight}
                    </Value>
                    <MultiLineRow>
                      <Row>
                        <Label>ko</Label>
                        <LongValue
                          onDoubleClick={(e) => {
                            handleUpdateMessages(e, "content");
                          }}
                          data-messageid={msg.id}
                          data-lan={"ko"}
                        >
                          {msg.content["ko"]}
                        </LongValue>
                      </Row>
                      <Row>
                        <Label>en</Label>
                        <LongValue
                          onDoubleClick={(e) => {
                            handleUpdateMessages(e, "content");
                          }}
                          data-messageid={msg.id}
                          data-lan={"en"}
                        >
                          {msg.content["en"]}
                        </LongValue>
                      </Row>
                    </MultiLineRow>
                    <Value onClick={() => handleRemoveMessage(msg.id)}>
                      삭제
                    </Value>
                  </Row>
                );
              })}
            </React.Fragment>
          );
        })}
    </Wrapper>
  );
};

export default God;
