import { HowlOptions } from "howler";
import React, {
  useContext,
  useEffect,
  useRef,
  useState
  } from "react";
import { useParams, withRouter } from "react-router-dom";
import styled from "styled-components";
import useSound from "use-sound";
import EndingBGM from "../Audios/Ending_BGM.wav";
import IntroBGM from "../Audios/Intro_BGM.wav";
import MainBGM from "../Audios/Main_BGM.wav";
import Credit from "../Components/Credit";
import Ending from "../Components/Ending";
import Intro from "../Components/Intro";
import Landing from "../Components/Landing";
import LogHistory from "../Components/LogHistory";
import MiniLog from "../Components/MiniLog";
import DeviceContext from "../Context/DeviceContext";
import useEarth from "../Hooks/useEarth";
import useHuman from "../Hooks/useHuman";
import useSettings from "../Hooks/useSettings";
import text from "../text-data";
import Earth from "./Earth";
import Human from "./Human";

const SizeAdjust = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  span {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const VerticalLine = styled.div`
  width: 2px;
  min-width: 1px;
  min-height: 100vh;
  background-color: #e6e6e6;
`;

const FullPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Home: React.FunctionComponent = () => {
  // 언어
  const { lan } = useParams<{ lan: Language }>();

  // 사운드
  const [loadingSound, setLoadingSound] = useState<boolean>(true);
  const [soundCheck, setSoundCheck] = useState<Array<boolean>>([
    false,
    false,
    false,
  ]);
  const [playIntro, { sound: introSound, stop: stopIntro }] =
    useSound<HowlOptions>(IntroBGM, {
      loop: true,
      preload: true,
      onload: () =>
        setSoundCheck((prev) => {
          const next = Array.from(prev);
          next[0] = true;
          return next;
        }),
    });
  const [playMain, { sound: mainSound, stop: stopMain }] =
    useSound<HowlOptions>(MainBGM, {
      loop: true,
      preload: true,
      onload: () =>
        setSoundCheck((prev) => {
          const next = Array.from(prev);
          next[1] = true;
          return next;
        }),
    });
  const [playEnding, { sound: endingSound, stop: stopEnding }] =
    useSound<HowlOptions>(EndingBGM, {
      loop: true,
      preload: true,
      onload: () =>
        setSoundCheck((prev) => {
          const next = Array.from(prev);
          next[2] = true;
          return next;
        }),
    });
  // 데이터
  const { device, needSizeAdjust } = useContext(DeviceContext);
  const { earth, restartEarth } = useEarth();
  const { human, createHumanData, clearHuman } = useHuman();
  const { settings } = useSettings();
  // 플로우
  const [stage, setStage] = useState<string>("LANDING");
  const [enterBridge, setEnterBridge] = useState<boolean>(false);
  const [causeOfDeath, setCauseOfDeath] = useState<CauseOfDeath>("ALIVE");
  const [vaccineReady, setVaccineReady] = useState<boolean>(false);
  const [disabledFoods, setDisabledFoods] = useState<Array<Food>>([]);
  const lifeT = useRef<NodeJS.Timeout>();
  const extinctT = useRef<NodeJS.Timeout>();
  const vaccineT = useRef<NodeJS.Timeout>();
  // 로그
  const [logQueue, setLogQueue] = useState<Array<Log>>([]);
  const [earthLogQueue, setEarthLogQueue] = useState<Array<Log>>([]);
  const [log, setLog] = useState<Array<Log>>([]);
  // 지구 생성 및 관리 - 지구는 현재 지구의 복사본을 가져온다
  const [localEarth, setLocalEarth] = useState<Earth>();
  // 인간 생성 및 관리
  const [localHuman, setLocalHuman] = useState<Human>();

  // 사운드 로드
  useEffect(() => {
    if (
      soundCheck[0] &&
      soundCheck[1] &&
      soundCheck[2] &&
      introSound &&
      mainSound &&
      endingSound
    ) {
      setLoadingSound(false);
    }
  }, [soundCheck, introSound, mainSound, endingSound]);

  // BGM 컨트롤
  useEffect(() => {
    if (!loadingSound) {
      switch (stage) {
        case "LANDING":
        case "INTRO":
        case "CREATE":
          stopMain();
          stopEnding();
          if (!introSound.playing()) {
            playIntro();
          }
          break;

        case "PLAY":
          stopIntro();
          stopEnding();
          if (!mainSound.playing()) {
            mainSound.volume(1);
            playMain();
          } else if (enterBridge) {
            mainSound.fade(1, 0, 10000);
          }
          break;

        case "ENDING":
        case "CREDIT":
          stopIntro();
          stopMain();
          if (!endingSound.playing()) {
            playEnding();
          }
          break;
      }
    }
  }, [stage, loadingSound, enterBridge]);

  // 데이터 로드
  useEffect(() => {
    if (earth && !localEarth) {
      setLocalEarth(earth);
    }
  }, [earth, localEarth]);

  useEffect(() => {
    if (human && !localHuman) {
      setLocalHuman(human);
    }
  }, [human, localHuman]);

  /**
   * 환경 흐름
   */
  useEffect(() => {
    // // 매 초 라이프 1씩 감소
    if (localHuman && localHuman.life > 0 && settings) {
      lifeT.current = setInterval(
        () => updateHuman({ life: settings.world.human.life }),
        settings.world.timeDistance * 1000
      );
    } else {
      if (lifeT.current) {
        clearInterval(lifeT.current);
      }
    }
    return () => {
      if (lifeT.current) {
        clearInterval(lifeT.current);
      }
    };
  }, [localHuman, settings]);

  /**
   * 엔딩 관리 트리거
   * 1. 질병률 엔딩
   * 2. 라이프 감소 엔딩
   * 3. 자연사 엔딩(자연사 엔딩은 나이 계산 방식으로 인해 PlayHuman에서 트리거)
   * 4. 온도상승 엔딩
   */

  // 질병률 엔딩 트리거
  useEffect(() => {
    if (
      !isGameOver() &&
      localEarth &&
      localEarth.disease >= 100 &&
      stage === "PLAY"
    ) {
      gameOver("DISEASE");
    }
  }, [localEarth]);

  // 라이프 감소 엔딩 트리거
  useEffect(() => {
    if (
      !isGameOver() &&
      localHuman &&
      localHuman.life <= 0 &&
      stage === "PLAY"
    ) {
      gameOver("NO_LIFE");
    }
  }, [localHuman]);

  // 온도 상승 엔딩 트리거
  useEffect(() => {
    if (
      !isGameOver() &&
      localEarth &&
      localEarth.temperature >= 6 &&
      stage === "PLAY"
    ) {
      gameOver("TEMPERATURE");
    }
  }, [localEarth]);

  /**
   * 게임 컨트롤 관련 트리거 모음
   */
  // 백신 활성화 트리거
  useEffect(() => {
    if (
      !isGameOver() &&
      localEarth &&
      localEarth.disease >= 70 &&
      !vaccineReady
    ) {
      setVaccineReady(true);
    }
  }, [localEarth, localEarth?.disease, vaccineReady]);

  // 백신을 안맞을 경우 질병률 지속적 증가
  useEffect(() => {
    if (
      !isGameOver() &&
      vaccineReady &&
      localHuman &&
      !localHuman.vaccination
    ) {
      vaccineT.current = setInterval(
        () => updateEarth({ disease: 0.5, temperature: 0 }),
        1000
      );
    }
    return () => {
      if (vaccineT.current) {
        clearInterval(vaccineT.current);
      }
    };
  }, [vaccineReady, localHuman, localHuman?.vaccination]);

  // 음식 비활성화 루트 트리거
  useEffect(() => {
    if (
      !isGameOver() &&
      localEarth &&
      localEarth.disease >= 60 &&
      !disabledFoods.includes("fish")
    ) {
      becomeExtinct("fish");
    }
  }, [localEarth]);

  useEffect(() => {
    if (
      !isGameOver() &&
      disabledFoods.includes("fish") &&
      !disabledFoods.includes("egg")
    ) {
      setTimeout(() => {
        becomeExtinct("egg");
      }, 10000);
    }
    if (
      !isGameOver() &&
      disabledFoods.includes("egg") &&
      !disabledFoods.includes("meal")
    ) {
      setTimeout(() => {
        becomeExtinct("meal");
      }, 20000);
    }
    if (
      !isGameOver() &&
      disabledFoods.includes("meal") &&
      !disabledFoods.includes("meat")
    ) {
      setTimeout(() => {
        becomeExtinct("meat");
      }, 10000);
    }
  }, [disabledFoods]);

  /**
   * 게임 플로우 관리 함수
   */
  function changeStageTo(nextStage: string) {
    if (nextStage === "LANDING") {
      restart();
    }
    setStage(nextStage);
  }

  function vaccinate() {
    if (
      !isGameOver() &&
      vaccineReady &&
      localHuman &&
      !localHuman.vaccination
    ) {
      updateHuman({ vaccination: true });
      updateEarth({ disease: -30, temperature: 0 });
      addLog({
        delta: "vaccine",
        content: text[lan].home.vaccineLog[0],
        type: "system",
      });
    }
  }

  function becomeExtinct(food: Food) {
    if (!isGameOver()) {
      setDisabledFoods((v) => [...v, food]);
      if (food === "fish") {
        addEarthLog({
          content: text[lan].home.extinctLog.fish[0],
          type: "system",
        });
      } else if (food === "egg") {
        addEarthLog({
          content: text[lan].home.extinctLog.egg[0],
          type: "system",
        });
        setTimeout(() => {
          addEarthLog({
            content: text[lan].home.extinctLog.egg[1],
            type: "system",
          });
        }, 8000);
      } else if (food === "meal") {
        addEarthLog({
          content: text[lan].home.extinctLog.meal[0],
          type: "system",
        });
      } else if (food === "meat") {
        addEarthLog({
          content: text[lan].home.extinctLog.meat[0],
          type: "system",
        });
        addEarthLog({
          content: text[lan].home.extinctLog.meat[1],
          type: "system",
        });
      }
    }
  }

  function isGameOver(): boolean {
    if (causeOfDeath === "ALIVE") {
      return false;
    } else {
      return true;
    }
  }

  function gameOver(causeOfDeath: CauseOfDeath) {
    if (!isGameOver() && stage === "PLAY") {
      setCauseOfDeath(causeOfDeath);
      updateHuman({ deathInfo: causeOfDeath, death: new Date().getTime() });
      setEnterBridge(true);
      if (lifeT.current) {
        clearInterval(lifeT.current);
      }
      if (vaccineT.current) {
        clearInterval(vaccineT.current);
      }
      if (extinctT.current) {
        clearInterval(extinctT.current);
      }
    }
  }

  function seeEndingScene() {
    if (stage === "PLAY") {
      changeStageTo("ENDING");
    }
  }

  function restart() {
    clearHuman();
    setLocalHuman(undefined);
    setLocalEarth(undefined);
    setEnterBridge(false);
    setCauseOfDeath("ALIVE");
    setVaccineReady(false);
    setDisabledFoods([]);
    setLogQueue([]);
    setEarthLogQueue([]);
    setLog([]);
  }

  /**
   * 로그 관리 함수
   */
  function addLog(log: Log) {
    setLogQueue((v) => [...v, log]);
  }

  function popLogQueue() {
    setLog((v) => [...v, logQueue[0]]);
    setLogQueue((v) => v.slice(1));
  }

  function addEarthLog(log: Log) {
    setEarthLogQueue((v) => [...v, log]);
  }

  function popEarthLogQueue() {
    setLog((v) => [...v, earthLogQueue[0]]);
    setEarthLogQueue((v) => v.slice(1));
  }

  /**
   * 플레이어 휴먼 관리 함수
   */
  function createHuman(name: string) {
    if (localEarth) {
      createHumanData(name, localEarth.id);
      changeStageTo("PLAY");
    }
    restartEarth();
  }

  function updateHuman(delta: UpdatableHumanAttributes) {
    if (!isGameOver() && localHuman) {
      const prevHuman = localHuman;
      if (delta.life) {
        prevHuman.life = prevHuman.life + delta.life;
      }
      if (delta.death) {
        prevHuman.death = delta.death;
      }
      if (delta.deathInfo) {
        prevHuman.deathInfo = delta.deathInfo;
      }
      if (delta.vaccination) {
        prevHuman.vaccination = delta.vaccination;
      }
      setLocalHuman(Object.assign({}, prevHuman));
    }
  }

  /**
   * 지구 관리 함수
   */
  function updateEarth(delta: UpdatableEarthAttributes) {
    if (!isGameOver() && localEarth) {
      const prevEarth = localEarth;
      prevEarth.disease = Math.min(100, prevEarth.disease + delta.disease);
      prevEarth.temperature = Math.min(
        6,
        prevEarth.temperature + delta.temperature
      );
      setLocalEarth(Object.assign({}, prevEarth));
    }
  }

  return needSizeAdjust ? (
    <SizeAdjust>
      <div>
        <span>GAIA</span>
      </div>
      <div>{text[lan].home.sizeAdjust[0]}</div>
      <div>{text[lan].home.sizeAdjust[1]}</div>
      <div>{text[lan].home.sizeAdjust[2]}</div>
      <div>{text[lan].home.sizeAdjust[3]}</div>
    </SizeAdjust>
  ) : (
    <Wrapper>
      {stage === "LANDING" && <Landing changeStageTo={changeStageTo} />}
      {stage === "INTRO" && <Intro changeStageTo={changeStageTo} lan={lan} />}
      {(stage === "CREATE" || stage === "PLAY") && (
        <>
          <FullPage>
            <Human
              human={localHuman}
              createHuman={createHuman}
              updateHuman={updateHuman}
              vaccinate={vaccinate}
              vaccineReady={vaccineReady}
              disabledFoods={disabledFoods}
              gameOver={gameOver}
              enterBridge={enterBridge}
              seeEndingScene={seeEndingScene}
              updateEarth={updateEarth}
              addLog={addLog}
              lan={lan}
            />
            {(device === "bgScreen" || device === "mdScreen") && (
              <>
                <VerticalLine />
                <Earth
                  earth={localEarth}
                  gameOver={gameOver}
                  earthLogQueue={earthLogQueue}
                  popEarthLogQueue={popEarthLogQueue}
                  lan={lan}
                />
              </>
            )}
            {!isGameOver() && (
              <MiniLog logQueue={logQueue} popLogQueue={popLogQueue} />
            )}
          </FullPage>
          <FullPage>
            <LogHistory log={log} />
          </FullPage>
        </>
      )}
      {stage === "ENDING" && (
        <Ending
          human={localHuman}
          earth={localEarth}
          causeOfDeath={causeOfDeath}
          changeStageTo={changeStageTo}
          lan={lan}
        />
      )}
      {stage === "CREDIT" && <Credit changeStageTo={changeStageTo} />}
    </Wrapper>
  );
};
export default withRouter(Home);
