import { HowlOptions } from "howler";
import React, { useState } from "react";
import styled from "styled-components";
import useSound from "use-sound";
import ClickSound from "../../Audios/Click_Flow.wav";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 104px;
  margin-bottom: 24px;
  span {
    font-size: 36px;
  }
`;

const Button = styled.div`
  font-size: 24px;
  &:hover {
    transition: color 0.2s ease-in;
    color: #b5b5b5;
    cursor: pointer;
  }
`;

const Copyright = styled.div`
  position: absolute;
  bottom: 36px;
  font-size: 16px;
  color: #c8c8c8;
  text-transform: uppercase;
  font-weight: normal;
`;

interface Props {
  changeStageTo: (nextStage: string) => void;
}

const Landing: React.FunctionComponent<Props> = ({ changeStageTo }: Props) => {
  const [loadingSound, setLoadingSound] = useState<boolean>(true);
  const [playClick] = useSound<HowlOptions>(ClickSound, {
    preload: true,
    volume: 0.4,
    onload: () => setLoadingSound(false),
  });

  return (
    <Wrapper>
      <Title>GAIA</Title>
      <Button
        onClick={() => {
          if (!loadingSound) {
            playClick();
          }
          changeStageTo("INTRO");
        }}
      >
        START
      </Button>
      <Copyright>COPYRIGHT 2021 YUNYOUNG JANG. ALL RIGHTS RESERVED</Copyright>
    </Wrapper>
  );
};

export default Landing;
