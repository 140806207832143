import { HowlOptions } from "howler";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useSound from "use-sound";
import ClickSound from "../../Audios/Click_Flow.wav";

interface StyledProps {
  badEnding?: boolean;
  shouldBlack?: boolean;
}

const Wrapper = styled.div<StyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
`;

const Detail = styled.div`
  position: relative;
  width: 687px;
  height: 712px;
  border: 2px solid white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const DetailExit = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 36px;
  height: 36px;
  border: 1px solid white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  &:hover {
    cursor: pointer;
  }
`;

const DetailCopyright = styled.div`
  position: absolute;
  width: 100%;
  bottom: 24px;
  text-align: center;
  font-size: 16px;
  color: white;
`;

const DetailContent = styled.div`
  width: 100%;
  height: calc(712px - 36px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 48px 40px;
`;

const DetailTitle = styled.div`
  font-size: 115px;
  margin-bottom: 32px;
`;

const Version = styled.div`
  position: relative;
  top: -16px;
  font-size: 18px;
  color: #5c5c5c;
`;

const TextLine = styled.div`
  font-family: Oxanium;
  font-size: 16px;
  color: #7b7b7b;
  margin: 10px;
  span {
    color: white;
  }
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 36px;
  right: 36px;
  width: 136px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 8px;
  border: 2px solid white;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

const RestartButton = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 48px;
  color: #b5b5b5;

  &:hover {
    transition: color 0.2s ease-in;
    color: white;
    cursor: pointer;
  }
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 150px;
`;

const FirstLine = styled.div`
  font-size: 80px;
`;

const SecondLine = styled.div`
  font-size: 80px;
`;

interface Props {
  changeStageTo: (nextStage: string) => void;
}

const Credit: React.FunctionComponent<Props> = ({ changeStageTo }: Props) => {
  const [loadingSound, setLoadingSound] = useState<boolean>(true);
  const [playClick] = useSound<HowlOptions>(ClickSound, {
    preload: true,
    volume: 0.4,
    onload: () => setLoadingSound(false),
  });
  const [openCredit, setOpenCredit] = useState<boolean>(false);

  useEffect(() => {
    let t: NodeJS.Timeout;

    function startTimer() {
      console.log("newEvent");
      if (t) {
        clearTimeout(t);
      }
      t = setTimeout(() => {
        changeStageTo("LANDING");
      }, 1000 * 60 * 3);
    }

    ["mousemove", "click"].forEach((event) => {
      window.addEventListener(event, startTimer);
    });
    startTimer();

    return () => {
      if (t) {
        clearTimeout(t);
      }
      ["mousemove", "click"].forEach((event) => {
        window.removeEventListener(event, startTimer);
      });
    };
  }, []);

  return (
    <Wrapper>
      {openCredit ? (
        <Detail>
          <DetailExit
            onClick={() => {
              if (!loadingSound) {
                playClick();
              }
              setOpenCredit((v) => !v);
            }}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="23.3613"
                y1="1.34561"
                x2="1.36132"
                y2="24.3456"
                stroke="white"
              />
              <line
                x1="22.6314"
                y1="24.3379"
                x2="0.631422"
                y2="0.337864"
                stroke="white"
              />
            </svg>
          </DetailExit>
          <DetailContent>
            <DetailTitle>
              GAIA
              <Version>Version. 1.0.0</Version>
            </DetailTitle>
            <TextLine>
              Art Direction :{" "}
              <span>
                Yunyoung Jang{" "}
                <a href="https://yunyoung.kr" target="_blank" rel="noreferrer">
                  (yunyoung.kr)
                </a>
              </span>
            </TextLine>
            <TextLine>
              Concept Art & Graphic Design : <span>Yunyoung Jang</span>
            </TextLine>
            <TextLine>
              Text Research : <span>Yunyoung Jang</span>
            </TextLine>
            <TextLine>
              Web Technical Direction : <span>Minwook Park</span>
            </TextLine>
            <TextLine>
              Music: <span>AIRAIR</span>
            </TextLine>
            <TextLine />
            <TextLine />
            <TextLine>
              Advisor: <span>Youngju Kim(Loopntale)</span>
            </TextLine>
            <TextLine>
              Special thanks: <span>Youngjin Oh</span>
            </TextLine>
            <TextLine />
            <TextLine />
            <TextLine>
              Manage: <span>Artcenter Nabi</span>
            </TextLine>
            <TextLine>
              Support: <span>Kocca</span>
            </TextLine>
          </DetailContent>
          <DetailCopyright>
            COPYRIGHT 2021 YUNYOUNG JANG. ALL RIGHTS RESERVED
          </DetailCopyright>
        </Detail>
      ) : (
        <RestartButton
          onClick={() => {
            if (!loadingSound) {
              playClick();
            }
            changeStageTo("LANDING");
          }}
        >
          <ButtonColumn>{`>>`}</ButtonColumn>
          <ButtonColumn>
            <FirstLine>{`NEXT`}</FirstLine>
            <SecondLine>{`GAIA`}</SecondLine>
          </ButtonColumn>
          <ButtonColumn>{`>>`}</ButtonColumn>
        </RestartButton>
      )}
      <ToggleButton
        onClick={() => {
          if (!loadingSound) {
            playClick();
          }
          setOpenCredit((v) => !v);
        }}
      >
        About
      </ToggleButton>
    </Wrapper>
  );
};

export default Credit;
