import React, { useContext } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch
  } from "react-router-dom";
import styled from "styled-components";
import DeviceContext from "../../Context/DeviceContext";
import routes from "../../routes";
import Earth from "../../Routes/Earth";
import God from "../../Routes/God";
import Home from "../../Routes/Home";
import Human from "../../Routes/Human";

const MobileView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  span {
    font-size: 24px;
  }
  div {
    text-align: center;
    padding: 0px 16px;
  }
`;

const AppPresenter: React.FunctionComponent = () => {
  const { device, loading } = useContext(DeviceContext);

  return loading ? (
    <div />
  ) : (
    <BrowserRouter>
      {device === "mobile" ? (
        <MobileView>
          <div>
            <span>GAIA</span>
          </div>
          <div>It does not support mobile devices.</div>
        </MobileView>
      ) : (
        <Switch>
          <Route path={routes.god} exact={true} component={God} />
          <Route path={routes.home} exact={true} component={Home} />
          <Redirect from={"*"} to={"/ko"} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default AppPresenter;
