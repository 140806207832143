import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EndingDiseaseImg from "../../Images/ending_disease.gif";
import EndingLifeImg from "../../Images/ending_life.gif";
import EndingTemperatureImg from "../../Images/ending_temperature.gif";
import text from "../../text-data";

interface StyledProps {
  sceneEnter?: boolean;
  sceneNo?: number;
  fontSize?: number;
  marginBottom?: number;
}

const Wrapper = styled.div<StyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const Scene = styled.div<StyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.sceneEnter ? 1 : 0)};
  transition: opacity 800ms ease-in-out;
`;

const EndingImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 32px;
`;

const Text = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-bottom: 80px;
`;

const TextLine = styled.div<StyledProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 24)}px;
  margin: 8px 0px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 8)}px;
`;

interface Props {
  human: Human | undefined;
  earth: Earth | undefined;
  causeOfDeath: CauseOfDeath;
  changeStageTo: (nextStage: string) => void;
  lan: Language;
}

const humanDummy: Human = {
  id: "1",
  name: "name",
  life: 100,
  earthId: "2",
  birth: new Date().getTime(),
  death: 0,
  deathInfo: "TEMPERATURE",
  vaccination: false,
};

const earthDummy = {
  id: "1",
  generation: 1,
  temperature: 1.1,
  disease: 30,
  birth: new Date().getTime(),
  death: 0,
};

const Ending: React.FunctionComponent<Props> = ({
  human = humanDummy,
  earth = earthDummy,
  causeOfDeath,
  changeStageTo,
  lan,
}: Props) => {
  const sceneCount: {
    [K in CauseOfDeath]: number;
  } = {
    ALIVE: -1,
    OLD: 1,
    TEMPERATURE: 1,
    DISEASE: 1,
    NO_LIFE: 1,
  };
  const [sceneNo, setSceneNo] = useState<number>(-1);
  const [sceneEnter, setSceneEnter] = useState<boolean>(false);

  useEffect(() => {
    if (sceneNo > -1) {
      const t = setTimeout(enterScene, 500);
    }
  }, [sceneNo]);

  useEffect(() => {
    if (sceneEnter) {
      setTimeout(exitScene, 8000);
    } else {
      // 씬의 첫 시작점
      setTimeout(nextScene, 1000);
    }
  }, [sceneEnter]);

  function enterScene() {
    setSceneEnter(true);
  }

  function exitScene() {
    setSceneEnter(false);
  }

  function nextScene() {
    if (sceneNo < sceneCount[causeOfDeath]) {
      setSceneNo((v) => v + 1);
    } else {
      changeStageTo("CREDIT");
    }
  }

  function calculateAge(birth: number, death: number) {
    // 10분 = 100살, 1분 = 10살, 6초 = 1살
    const second = (death - birth) / 1000;
    const age = Math.floor(second / 6) + 1;
    return age;
  }

  return (
    <Wrapper sceneNo={sceneNo}>
      {human && earth && (
        <>
          {causeOfDeath === "TEMPERATURE" && (
            <>
              {sceneNo === 0 && (
                <Scene sceneEnter={sceneEnter}>
                  <EndingImage src={EndingTemperatureImg} />
                  <Text>
                    <TextLine fontSize={30}>
                      {text[lan].ending.temperature.textLine[0]}
                    </TextLine>
                    <TextLine fontSize={30} />
                    <TextLine>
                      {text[lan].ending.temperature.textLine[1]}
                    </TextLine>
                  </Text>
                </Scene>
              )}
              {sceneNo === 1 && (
                <Scene sceneEnter={sceneEnter}>
                  <Text>
                    <TextLine fontSize={38} marginBottom={72}>
                      {text[lan].ending.common.record(
                        human.name,
                        earth.generation,
                        calculateAge(human.birth, human.death)
                      )}
                    </TextLine>
                    <TextLine fontSize={26}>
                      {text[lan].ending.common.textLine[0]}
                    </TextLine>
                    <TextLine fontSize={26}>
                      {text[lan].ending.common.textLine[1]}
                    </TextLine>
                  </Text>
                </Scene>
              )}
            </>
          )}
          {causeOfDeath === "DISEASE" && (
            <>
              {sceneNo === 0 && (
                <Scene sceneEnter={sceneEnter}>
                  <EndingImage src={EndingDiseaseImg} />
                  <Text>
                    <TextLine fontSize={30}>
                      {text[lan].ending.disease.textLine[0]}
                    </TextLine>
                    <TextLine fontSize={30} />
                    <TextLine>{text[lan].ending.disease.textLine[1]}</TextLine>
                  </Text>
                </Scene>
              )}
              {sceneNo === 1 && (
                <Scene sceneEnter={sceneEnter}>
                  <Text>
                    <TextLine fontSize={38} marginBottom={72}>
                      {text[lan].ending.common.record(
                        human.name,
                        earth.generation,
                        calculateAge(human.birth, human.death)
                      )}
                    </TextLine>
                    <TextLine fontSize={26}>
                      {text[lan].ending.common.textLine[0]}
                    </TextLine>
                    <TextLine fontSize={26}>
                      {text[lan].ending.common.textLine[1]}
                    </TextLine>
                  </Text>
                </Scene>
              )}
            </>
          )}
          {causeOfDeath === "NO_LIFE" && (
            <>
              {sceneNo === 0 && (
                <Scene sceneEnter={sceneEnter}>
                  <EndingImage src={EndingLifeImg} />
                  <Text>
                    <TextLine fontSize={30}>
                      {text[lan].ending.noLife.textLine[0]}
                    </TextLine>
                    <TextLine fontSize={30} />
                    <TextLine>{text[lan].ending.noLife.textLine[1]}</TextLine>
                  </Text>
                </Scene>
              )}
              {sceneNo === 1 && (
                <Scene sceneEnter={sceneEnter}>
                  <Text>
                    <TextLine fontSize={38} marginBottom={72}>
                      {text[lan].ending.common.record(
                        human.name,
                        earth.generation,
                        calculateAge(human.birth, human.death)
                      )}
                    </TextLine>
                    <TextLine fontSize={26}>
                      {text[lan].ending.common.textLine[0]}
                    </TextLine>
                    <TextLine fontSize={26}>
                      {text[lan].ending.common.textLine[1]}
                    </TextLine>
                  </Text>
                </Scene>
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Ending;
