import { HowlOptions } from "howler";
import React, { useState } from "react";
import styled from "styled-components";
import useSound from "use-sound";
import ClickSound from "../../Audios/Click_Flow.wav";
import walkImg4 from "../../Images/walk_4.gif";
import text from "../../text-data";

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  margin-bottom: 48px;
`;

const Input = styled.input`
  width: 360px;
  height: 72px;
  border: 3px solid black;
  margin-bottom: 36px;
  font-size: 24px;
  &::placeholder {
    font-size: 16px;
    color: #dddddd;
  }
`;

const Button = styled.div`
  width: 192px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  &:hover {
    transition: color 0.2s ease-in;
    color: #b5b5b5;
    cursor: pointer;
  }
`;

interface Props {
  inputName: any;
  handleCreateHuman: any;
  lan: Language;
}

const CreateHuman: React.FunctionComponent<Props> = ({
  inputName,
  handleCreateHuman,
  lan,
}: Props) => {
  const [loadingSound, setLoadingSound] = useState<boolean>(true);
  const [playClick] = useSound<HowlOptions>(ClickSound, {
    preload: true,
    volume: 0.4,
    onload: () => setLoadingSound(false),
  });
  return (
    <Wrapper>
      <Img src={walkImg4} />
      <Input
        onChange={inputName.onChange}
        value={inputName.value}
        placeholder={text[lan].createHuman.placeholder[0]}
      />
      <Button
        onClick={() => {
          if (!loadingSound) {
            playClick();
          }
          handleCreateHuman();
        }}
      >
        Click
      </Button>
    </Wrapper>
  );
};

export default CreateHuman;
