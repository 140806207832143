import { HowlOptions } from "howler";
import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import useSound from "use-sound";
import ClickSound from "../../Audios/Click_Game.wav";
import useSettings from "../../Hooks/useSettings";
import bubbleImg from "../../Images/bubble.png";
import cloudImg from "../../Images/cloud.png";
import nightImg from "../../Images/night.png";
import spotlightImg from "../../Images/spotlight.png";
import text from "../../text-data";
import EffectCanvas from "../EffectCanvas";
import HumanImage from "../HumanImage";
import {
  EggIcon,
  FishIcon,
  FoodIcon,
  InsectIcon,
  LightIcon,
  MealIcon,
  MeatIcon,
  VaccineIcon,
  LifeEmpty,
  LifeFull,
  LifeHalf,
  Heart,
} from "../Icons";

const FOOD_ITEM: Array<Food> = ["fish", "insect", "meat", "meal", "egg"];
const HELPER_TIME = 4000;
const NIGHT_TIME = 7000;
const HIT_TIME = 3000;

interface StyledProps {
  isLightOn?: boolean;
  isNight?: boolean;
  disabled?: boolean;
  vaccination?: boolean;
  tooltipPos?: { left: number; bottom: number };
  isLoop?: boolean;
  isHelperOnScreen?: boolean;
  isShow?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const NameLabel = styled.div`
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const AgeLabel = styled.div`
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const LifeLabel = styled.div`
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
`;

const BackgroundImage = styled.img<StyledProps>`
  position: absolute;
  height: 100%;
  opacity: ${(props) => (props.isNight ? 0.95 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const CloudBlinkFrames = keyframes`
0%{
  opacity: 1;
}50%{
  opacity: 0.7;
}100%{
  opacity: 1;
}
`;

const CloudBlink = css`
  animation: ${CloudBlinkFrames} 1s ease-in-out infinite;
`;

const CloudImage = styled.img<StyledProps>`
  position: absolute;
  top: 0;
  width: 70%;
  display: ${(props) => (props.isShow ? "block" : "none")};
  ${CloudBlink}
`;

const SpotlightImage = styled.img<StyledProps>`
  position: absolute;
  height: 100%;
  opacity: ${(props) => (props.isLightOn ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const Buttons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const disabledCSS = css`
  border: 2px solid #d3d3d3;
  img {
    opacity: 0.3;
  }
  &:hover {
    cursor: not-allowed;
  }
`;

const abledCSS = css`
  border: 2px solid black;
  &:hover {
    cursor: pointer;
  }
`;

const Button = styled.div<StyledProps>`
  width: 144px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin: 6px;
  border-radius: 8px;
  ${(props) =>
    props.vaccination ? disabledCSS : props.disabled ? disabledCSS : abledCSS};
`;

const SubButtons = styled.div`
  position: absolute;
  top: calc(56px + 24px);
  display: flex;
  z-index: 10;
`;

const SubButton = styled.div<StyledProps>`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  border-radius: 8px;
  ${(props) => (props.disabled ? disabledCSS : abledCSS)};
`;

const Tooltip = styled.div<StyledProps>`
  position: fixed;
  left: ${(props) =>
    props.tooltipPos ? `${props.tooltipPos.left}px` : `-100px`};
  bottom: ${(props) =>
    props.tooltipPos ? `${props.tooltipPos.bottom}px` : `-100px`};
  border: 2px solid black;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  font-size: 12px;
  z-index: 20;
  div {
    margin: 2px 0px;
  }
`;

const blinkFrame = keyframes`
  0%{
    opacity: 1;
  }35%{
    opacity: 1;
  }40%{
    opacity: 0;
  }100%{
    opacity: 0;
  }
`;

const loopAnimationCSS = (isHelperOnScreen: boolean) => css`
  /* animation: ${blinkFrame} ${HELPER_TIME}ms ease-in ${HELPER_TIME / 2}ms
    infinite; */
  opacity: ${isHelperOnScreen ? 1 : 0};
  transition: opacity 300ms ease-in;
`;

const hitFrame = keyframes`
  0%{
    transform: scale(1);
    opacity: 1;
  }5%{
    transform: scale(1.1);
    opacity: 1;
  }10%{
    transform: scale(1);
    opacity: 1;
  }90%{
    transform: translateY(0px);
    opacity: 1;
  }100%{
    transform: translateY(-10px);
    opacity: 0;
  }
`;

const hitAnimationCSS = css`
  animation: ${hitFrame} ${HIT_TIME}ms ease-in;
`;

const Helper = styled.div<StyledProps>`
  position: absolute;
  top: 120px;
  right: 60px;
  width: 72px;
  height: 72px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isLoop &&
    props.isHelperOnScreen &&
    loopAnimationCSS(props.isHelperOnScreen)};
  ${(props) => !props.isLoop && hitAnimationCSS};
  z-index: 10;
  img {
    z-index: 11;
  }
`;

const HelperBubble = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5 !important;
`;

interface Props {
  human: Human;
  updateHuman: (delta: UpdatableHumanAttributes) => void;
  vaccinate: () => void;
  vaccineReady: boolean;
  disabledFoods: Array<Food>;
  gameOver: (causeOfDeath: CauseOfDeath) => void;
  enterBridge: boolean;
  seeEndingScene: () => void;
  updateEarth: (delta: UpdatableEarthAttributes) => void;
  addLog: (log: Log) => void;
  lan: Language;
}

const PlayHuman: React.FunctionComponent<Props> = ({
  human,
  updateHuman,
  vaccinate,
  vaccineReady,
  disabledFoods,
  gameOver,
  enterBridge,
  seeEndingScene,
  updateEarth,
  addLog,
  lan,
}: Props) => {
  const [loadingSound, setLoadingSound] = useState<boolean>(true);
  const [playClick] = useSound<HowlOptions>(ClickSound, {
    preload: true,
    onload: () => setLoadingSound(false),
  });
  const [age, setAge] = useState<number>(1);
  const { settings, messages } = useSettings();
  const [foodSubButtonOn, setFoodSubButtonOn] = useState<boolean>(false);
  const [bone, setBone] = useState<Array<Food>>([]);
  const [isNight, setIsNight] = useState<boolean>(false);
  const [isLightOn, setIsLightOn] = useState<boolean>(false);
  const [tooltipPos, setTooltipPos] = useState<{
    left: number;
    bottom: number;
  }>({ left: -100, bottom: -100 });
  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [helper, setHelper] = useState<Food | "light" | "vaccine">("meat");
  const [isHelperOnScreen, setIsHelperOnScreen] = useState<boolean>(false);
  const [hit, setHit] = useState<boolean>(false);
  const [shakeFood, setShakeFood] = useState<boolean>(true);
  const [randomFood, setRandomFood] = useState<Food>("meat");
  const ageT = useRef<NodeJS.Timeout>();
  const nightT = useRef<NodeJS.Timeout>();

  useEffect(() => {
    function handleMousemove(e: MouseEvent) {
      const { clientX, clientY } = e;
      setTooltipPos({
        left: clientX + 8,
        bottom: window.innerHeight - clientY + 8,
      });
    }
    window.addEventListener("mousemove", handleMousemove);
    return () => window.removeEventListener("mousemove", handleMousemove);
  }, []);

  useEffect(() => {
    if (human) {
      ageT.current = setInterval(() => {
        setAge(calculateAge(human.birth));
      }, 1000);
    }
    return () => {
      if (ageT.current) {
        clearInterval(ageT.current);
      }
    };
  }, []);

  useEffect(() => {
    if (human) {
      nightT.current = setInterval(() => {
        setIsNight((v) => !v);
      }, NIGHT_TIME);
    }
    return () => {
      if (nightT.current) {
        clearInterval(nightT.current);
      }
    };
  }, []);

  useEffect(() => {
    if (human.deathInfo !== "ALIVE" && ageT.current && nightT.current) {
      clearInterval(ageT.current);
      clearInterval(nightT.current);
    }
  }, [human.deathInfo]);

  useEffect(() => {
    if (!isNight) {
      setIsLightOn(false);
    }
  }, [isNight]);

  // 헬퍼 아이템 변경 로직 (최소 HELPER_TIME 마다 한번씩 업데이트)
  useEffect(() => {
    // 음식은 HELPER_TIME 간격으로 랜덤화됨. Hit하지 않은 경우만.
    let t: NodeJS.Timeout;
    if (!hit) {
      t = setInterval(() => {
        setShakeFood((v) => !v);
      }, HELPER_TIME);
    }
    return () => clearInterval(t);
  }, [hit]);

  useEffect(() => {
    // 헬퍼는 항상 HELPER_TIME 간격으로 반복함. 단 Hit하지 않은 경우만.
    let t: NodeJS.Timeout;
    if (!hit) {
      if (isHelperOnScreen) {
        t = setTimeout(() => {
          setIsHelperOnScreen(false);
        }, HELPER_TIME);
      } else {
        t = setTimeout(() => {
          setIsHelperOnScreen(true);
        }, HELPER_TIME);
      }
    }
    return () => clearTimeout(t);
  }, [hit, isHelperOnScreen]);

  useEffect(() => {
    const availableFood = FOOD_ITEM.filter(
      (food) => !disabledFoods.includes(food)
    );
    const newFood =
      availableFood[Math.floor(Math.random() * availableFood.length)];
    setRandomFood(newFood);
  }, [shakeFood, disabledFoods]);

  useEffect(() => {
    if (!isHelperOnScreen) {
      if (isNight && !isLightOn) {
        setHelper("light");
      } else if (vaccineReady && !human.vaccination) {
        setHelper("vaccine");
      } else {
        setHelper(randomFood);
      }
    }
  }, [
    isNight,
    isLightOn,
    vaccineReady,
    human.vaccination,
    randomFood,
    isHelperOnScreen,
  ]);

  /**
   * 함수 모음
   */
  function handleSubButtonsOpen(category: string) {
    if (!loadingSound) {
      playClick();
    }
    if (category === "food") {
      setFoodSubButtonOn((v) => !v);
    }
  }

  function hideSubButtons() {
    setFoodSubButtonOn(false);
  }

  function calculateAge(birth: number) {
    // 10분 = 100살, 1분 = 10살, 6초 = 1살
    const now = new Date().getTime();
    const second = (now - birth) / 1000;
    const age = Math.floor(second / 6) + 1;
    return age;
  }

  function lifeToIcon(life: number): Array<string> {
    let icon = "";
    let vLife = Math.min(100, life);
    for (let i = 0; i < Math.floor(vLife / 20); i++) {
      icon += "F";
    }
    vLife = vLife % 20;
    if (vLife >= 10) {
      icon += "F";
    } else if (vLife > 0) {
      icon += "H";
    }
    icon = icon.padEnd(5, "E");
    return icon.split("");
  }

  function eat(food: Food) {
    checkHit(food);
    if (!loadingSound) {
      playClick();
    }
    if (human.deathInfo === "ALIVE" && settings && messages) {
      const delta: FoodDelta = settings.food[food];
      updateEarth({ disease: delta.disease, temperature: delta.temperature });
      updateHuman({ life: delta.life });
      const foodMessages = messages.filter(
        (message) => message.category === food
      );
      const foodText =
        foodMessages[Math.floor(Math.random() * foodMessages.length)].content[
          lan
        ];
      addLog({
        delta: delta,
        content: foodText,
        type: "user",
      });
      setBone([food]);
    }
    hideTooltip();
  }

  function lightToggle() {
    checkHit("light");
    if (!loadingSound) {
      playClick();
    }
    if (human.deathInfo === "ALIVE" && settings && messages) {
      if (isLightOn) {
        const delta: LightDelta = settings.etc.lightoff;
        updateEarth({ disease: delta.disease, temperature: delta.temperature });
        updateHuman({ life: delta.life });
        const lightOffMessages = messages.filter(
          (message) => message.category === "lightoff"
        );
        const lightOffText =
          lightOffMessages[Math.floor(Math.random() * lightOffMessages.length)]
            .content[lan];
        addLog({
          delta: delta,
          content: lightOffText,
          type: "user",
        });
        setIsLightOn(false);
      } else {
        const delta: LightDelta = settings.etc.lighton;
        updateEarth({ disease: delta.disease, temperature: delta.temperature });
        updateHuman({ life: delta.life });
        const lightOnMessages = messages.filter(
          (message) => message.category === "lighton"
        );
        const lightOnText =
          lightOnMessages[Math.floor(Math.random() * lightOnMessages.length)]
            .content[lan];
        addLog({
          delta: delta,
          content: lightOnText,
          type: "user",
        });
        setIsLightOn(true);
      }
    }
    hideSubButtons();
    hideTooltip();
  }

  function handleVaccine() {
    if (vaccineReady) {
      checkHit("vaccine");
      if (!loadingSound) {
        playClick();
      }
      vaccinate();
    }
    hideTooltip();
  }

  function showTooltip(
    data:
      | Food
      | "lighton"
      | "lightoff"
      | "vaccine-1"
      | "vaccine-2"
      | "vaccine-3"
  ) {
    if (settings) {
      // 툴팁 내용 설정
      if (data === "lighton" || data === "lightoff") {
        const delta = settings.etc[data];
        const content = `
          <div>${text[lan].playHuman.tooltip.life} : ${delta.life}</div>
          <div>${text[lan].playHuman.tooltip.temperature} : ${delta.temperature}</div>
          <div>${text[lan].playHuman.tooltip.disease} : ${delta.disease}</div>
        `;
        setTooltipContent(content);
      } else if (data === "vaccine-1") {
        const content = text[lan].playHuman.tooltip.vaccine[0];
        setTooltipContent(content);
      } else if (data === "vaccine-2") {
        const content = text[lan].playHuman.tooltip.vaccine[0];
        setTooltipContent(content);
      } else if (data === "vaccine-3") {
        const content = text[lan].playHuman.tooltip.vaccine[1];
        setTooltipContent(content);
      } else {
        if (disabledFoods.includes(data)) {
          const content = text[lan].playHuman.tooltip.extinct[data];
          setTooltipContent(content);
        } else {
          const delta = settings.food[data];
          const content = `
          <div>${text[lan].playHuman.tooltip.life} : ${delta.life}</div>
          <div>${text[lan].playHuman.tooltip.temperature} : ${delta.temperature}</div>
          <div>${text[lan].playHuman.tooltip.disease} : ${delta.disease}</div>
          `;
          setTooltipContent(content);
        }
      }
    }
  }

  function hideTooltip() {
    setTooltipContent("");
  }

  function checkHit(action: Food | "light" | "vaccine") {
    if (!hit && isHelperOnScreen && action === helper) {
      setHit(true);
      setIsHelperOnScreen(false);
      setTimeout(() => {
        setHit(false);
      }, HIT_TIME);
    }
  }

  return (
    <Wrapper>
      {human && (
        <Content>
          <NameLabel>{human.name}</NameLabel>
          <AgeLabel>{text[lan].playHuman.age(age)}</AgeLabel>
          <LifeLabel>
            {lifeToIcon(human.life).map((icon, idx) => {
              if (icon === "F") {
                return <LifeFull size={30} key={idx} />;
              } else if (icon === "H") {
                return <LifeHalf size={30} key={idx} />;
              } else {
                return <LifeEmpty size={30} key={idx} />;
              }
            })}
          </LifeLabel>
          <ImageWrapper>
            <EffectCanvas food={bone} />
            <BackgroundImage src={nightImg} isNight={isNight} />
            <CloudImage src={cloudImg} isShow={human.vaccination} />
            <SpotlightImage src={spotlightImg} isLightOn={isLightOn} />
            <HumanImage
              enterBridge={enterBridge}
              seeEndingScene={seeEndingScene}
              isLightOn={isLightOn}
              isNight={isNight}
            />
            {human.deathInfo === "ALIVE" &&
              (hit ? (
                <Helper>
                  <HelperBubble src={bubbleImg} />
                  <Heart size={32} />
                </Helper>
              ) : (
                <Helper isLoop isHelperOnScreen={isHelperOnScreen}>
                  <HelperBubble src={bubbleImg} />
                  {helper === "egg" && <EggIcon size={36} />}
                  {helper === "fish" && <FishIcon size={36} />}
                  {helper === "insect" && <InsectIcon size={36} />}
                  {helper === "meal" && <MealIcon size={36} />}
                  {helper === "meat" && <MeatIcon size={36} />}
                  {helper === "light" && <LightIcon size={36} />}
                  {helper === "vaccine" && <VaccineIcon size={36} />}
                </Helper>
              ))}
          </ImageWrapper>
          <Buttons>
            <Button onClick={() => handleSubButtonsOpen("food")}>
              <FoodIcon size={40} />
              {(foodSubButtonOn || disabledFoods.length > 0) && (
                <SubButtons>
                  <SubButton
                    disabled={disabledFoods.includes("meat")}
                    onClick={
                      disabledFoods.includes("meat")
                        ? hideTooltip
                        : () => eat("meat")
                    }
                    onMouseMove={() => showTooltip("meat")}
                    onMouseLeave={hideTooltip}
                  >
                    <MeatIcon size={40} />
                  </SubButton>
                  <SubButton
                    disabled={disabledFoods.includes("egg")}
                    onClick={
                      disabledFoods.includes("egg")
                        ? hideTooltip
                        : () => eat("egg")
                    }
                    onMouseMove={() => showTooltip("egg")}
                    onMouseLeave={hideTooltip}
                  >
                    <EggIcon size={40} />
                  </SubButton>
                  <SubButton
                    disabled={disabledFoods.includes("fish")}
                    onClick={
                      disabledFoods.includes("fish")
                        ? hideTooltip
                        : () => eat("fish")
                    }
                    onMouseMove={() => showTooltip("fish")}
                    onMouseLeave={hideTooltip}
                  >
                    <FishIcon size={40} />
                  </SubButton>
                  <SubButton
                    disabled={disabledFoods.includes("meal")}
                    onClick={
                      disabledFoods.includes("meal")
                        ? hideTooltip
                        : () => eat("meal")
                    }
                    onMouseMove={() => showTooltip("meal")}
                    onMouseLeave={hideTooltip}
                  >
                    <MealIcon size={40} />
                  </SubButton>
                  <SubButton
                    disabled={disabledFoods.includes("insect")}
                    onClick={
                      disabledFoods.includes("insect")
                        ? hideTooltip
                        : () => eat("insect")
                    }
                    onMouseMove={() => showTooltip("insect")}
                    onMouseLeave={hideTooltip}
                  >
                    <InsectIcon size={40} />
                  </SubButton>
                </SubButtons>
              )}
            </Button>
            <Button
              onClick={lightToggle}
              onMouseMove={() =>
                showTooltip(isLightOn ? "lightoff" : "lighton")
              }
              onMouseLeave={hideTooltip}
            >
              <LightIcon size={40} />
            </Button>
            <Button
              disabled={!vaccineReady}
              vaccination={human.vaccination}
              onClick={handleVaccine}
              onMouseMove={() =>
                showTooltip(
                  human.vaccination
                    ? "vaccine-3"
                    : vaccineReady
                    ? "vaccine-2"
                    : "vaccine-1"
                )
              }
              onMouseLeave={hideTooltip}
            >
              <VaccineIcon size={40} />
            </Button>
          </Buttons>
        </Content>
      )}
      {tooltipContent !== "" && (
        <Tooltip
          dangerouslySetInnerHTML={{ __html: tooltipContent }}
          style={tooltipPos}
        />
      )}
    </Wrapper>
  );
};

export default PlayHuman;
