import { HowlOptions } from "howler";
import Matter, {
  Bodies,
  Engine,
  Render,
  Runner,
  World
  } from "matter-js";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useSound from "use-sound";
import BoneDropSound from "../../Audios/BoneDrop.mp3";
import eggBoneImg from "../../Images/bones/egg.png";
import fishBoneImg from "../../Images/bones/fish.png";
import insectBoneImg from "../../Images/bones/insect.png";
import mealBoneImg from "../../Images/bones/meal.png";
import meatBoneImg from "../../Images/bones/meat.png";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

interface Props {
  food: Array<Food>;
}

const EffectCanvas: React.FunctionComponent<Props> = ({ food }: Props) => {
  const [loadingSound, setLoadingSound] = useState<boolean>(true);
  const [playBoneDrop] = useSound<HowlOptions>(BoneDropSound, {
    preload: true,
    onload: () => setLoadingSound(false),
  });
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 280,
    height: 280,
  });
  const scene = useRef<HTMLDivElement | null>(null);
  const render = useRef<Render>();
  const engine = useRef<Engine>();

  useEffect(() => {
    if (scene.current) {
      engine.current = Engine.create({
        gravity: {
          y: 2,
        },
      });

      render.current = Render.create({
        element: scene.current,
        engine: engine.current,
        options: {
          width: size.width,
          height: size.height,
          wireframes: false,
          background: "transparent",
        },
      });

      // boundaries
      World.add(engine.current.world, [
        Bodies.rectangle(size.width / 2, -10, size.width, 20, {
          isStatic: true,
          render: { fillStyle: "transparent" },
        }),
        Bodies.rectangle(-5, size.height / 2, 20, size.height, {
          isStatic: true,
          render: { fillStyle: "transparent" },
        }),
        Bodies.rectangle(size.width / 2, size.height + 5, size.width, 20, {
          isStatic: true,
          render: { fillStyle: "transparent" },
        }),
        Bodies.rectangle(size.width + 5, size.height / 2, 20, size.height, {
          isStatic: true,
          render: { fillStyle: "transparent" },
        }),
      ]);

      // run the engine
      Runner.run(engine.current);
      Render.run(render.current);
    }
    // unmount
    return () => {
      if (render.current && engine.current) {
        Render.stop(render.current);
        World.clear(engine.current.world, false);
        Engine.clear(engine.current);
        render.current.canvas.remove();
        render.current.textures = {};
      }
    };
  }, []);

  useEffect(() => {
    function getSize() {
      if (scene.current) {
        const { width, height } = scene.current.getBoundingClientRect();
        setSize({ width, height });
      }
    }
    getSize();

    if (scene.current) {
      scene.current.addEventListener("resize", getSize);
    }
    return () => {
      if (scene.current) {
        scene.current.removeEventListener("resize", getSize);
      }
    };
  }, [scene.current]);

  useEffect(() => {
    if (food.length > 0) {
      dropBone(food[0]);
    }
  }, [food]);

  function dropBone(food: Food) {
    const texture = {
      meat: meatBoneImg,
      meal: mealBoneImg,
      fish: fishBoneImg,
      egg: eggBoneImg,
      insect: insectBoneImg,
    };
    const bones: Array<Matter.Body> = [];
    if (food === "meat") {
      const headCount = Math.max(2, Math.floor(Math.random() * 5));
      for (let i = 0; i < headCount; i++) {
        const dropPosX = Math.max(Math.random() * (size.width - 25), 25);
        const dropPosY = Math.max(Math.random() * 10 + 1);
        const bone = Bodies.rectangle(dropPosX, dropPosY, 32, 32, {
          mass: 1,
          restitution: 0.1,
          friction: 0.01,
          angle: Math.random() * Math.PI,
          chamfer: { radius: [32, 3, 32, 3] },
          render: {
            strokeStyle: "black",
            sprite: {
              texture: texture[food],
              xScale: 0.8,
              yScale: 0.8,
            },
          },
        });
        bones.push(bone);
      }
      for (let i = 0; i < headCount * 3; i++) {
        const dropPosX = Math.max(Math.random() * (size.width - 25), 25);
        const dropPosY = Math.max(Math.random() * 10 + 1);
        const bone = Bodies.rectangle(dropPosX, dropPosY, 32, 32, {
          mass: 1,
          restitution: 0.1,
          friction: 0.01,
          angle: Math.random() * Math.PI,
          chamfer: { radius: [32, 3, 32, 3] },
          render: {
            strokeStyle: "black",
            sprite: {
              texture: texture["egg"],
              xScale: 1,
              yScale: 1,
            },
          },
        });
        bones.push(bone);
      }
    } else if (food === "meal") {
      const boneCount = Math.max(24, Math.floor(Math.random() * 36));
      for (let i = 0; i < boneCount; i++) {
        const dropPosX = Math.max(Math.random() * (size.width - 25), 25);
        const dropPosY = Math.max(Math.random() * 10 + 1);
        const bone = Bodies.rectangle(dropPosX, dropPosY, 24, 24, {
          mass: 1,
          restitution: 0.1,
          friction: 0.01,
          angle: Math.random() * Math.PI,
          chamfer: { radius: [24, 2, 24, 2] },
          render: {
            strokeStyle: "black",
            sprite: {
              texture: texture[food],
              xScale: 0.4,
              yScale: 0.4,
            },
          },
        });
        bones.push(bone);
      }
    } else if (food === "insect") {
      const boneCount = Math.max(16, Math.floor(Math.random() * 24));
      for (let i = 0; i < boneCount; i++) {
        const dropPosX = Math.max(Math.random() * (size.width - 25), 25);
        const dropPosY = Math.max(Math.random() * 10 + 1);
        const bone = Bodies.rectangle(dropPosX, dropPosY, 24, 24, {
          mass: 1,
          restitution: 0.1,
          friction: 0.01,
          angle: Math.random() * Math.PI,
          chamfer: { radius: [24, 2, 24, 2] },
          render: {
            strokeStyle: "black",
            sprite: {
              texture: texture[food],
              xScale: 0.4,
              yScale: 0.4,
            },
          },
        });
        bones.push(bone);
      }
    } else if (food === "egg") {
      const boneCount = Math.max(16, Math.floor(Math.random() * 24));
      for (let i = 0; i < boneCount; i++) {
        const dropPosX = Math.max(Math.random() * (size.width - 25), 25);
        const dropPosY = Math.max(Math.random() * 10 + 1);
        const bone = Bodies.rectangle(dropPosX, dropPosY, 28, 28, {
          mass: 1,
          restitution: 0.1,
          friction: 0.01,
          angle: Math.random() * Math.PI,
          chamfer: { radius: [28, 3, 28, 3] },
          render: {
            strokeStyle: "black",
            sprite: {
              texture: texture[food],
              xScale: 0.7,
              yScale: 0.7,
            },
          },
        });
        bones.push(bone);
      }
    } else if (food === "fish") {
      const boneCount = Math.max(10, Math.floor(Math.random() * 12));
      for (let i = 0; i < boneCount; i++) {
        const dropPosX = Math.max(Math.random() * (size.width - 25), 25);
        const dropPosY = Math.max(Math.random() * 10 + 1);
        const bone = Bodies.rectangle(dropPosX, dropPosY, 28, 28, {
          mass: 1,
          restitution: 0.1,
          friction: 0.01,
          angle: Math.random() * Math.PI,
          chamfer: { radius: [28, 3, 28, 3] },
          render: {
            strokeStyle: "black",
            sprite: {
              texture: texture[food],
              xScale: 0.7,
              yScale: 0.7,
            },
          },
        });
        bones.push(bone);
      }
    }

    if (engine.current) {
      World.add(engine.current.world, bones);
      bones.forEach((bone) => {
        setTimeout(() => {
          if (engine.current) {
            World.remove(engine.current.world, bone);
          }
        }, 20000);
      });
      if (!loadingSound) {
        setTimeout(() => {
          playBoneDrop();
        }, 400);
      }
    }
  }

  return <Wrapper ref={scene} />;
};

export default EffectCanvas;
