import React from "react";
import styled, { keyframes } from "styled-components";

interface StyledProps {
  type?: LogType;
  isVaccine?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
`;

const fadeInFrames = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

const LogBox = styled.div<StyledProps>`
  width: 80vw;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  background-color: ${(props) => (props.type === "user" ? "white" : "black")};
  color: ${(props) =>
    props.type === "user" ? "black" : props.isVaccine ? "#ff0000" : "white"};
  border: 2px solid black;
  border-radius: 20px;
  padding: 16px 36px;
  margin-bottom: 16px;
  animation: ${fadeInFrames} 500ms ease-in;
`;

interface Props {
  log: Array<Log>;
}

const LogHistory: React.FunctionComponent<Props> = ({ log }: Props) => {
  return (
    <Wrapper>
      {log.map((log, idx) => (
        <LogBox key={idx} type={log.type} isVaccine={log.delta === "vaccine"}>
          {log.content}
        </LogBox>
      ))}
    </Wrapper>
  );
};

export default LogHistory;
