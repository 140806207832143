const text = {
  ko: {
    home: {
      extinctLog: {
        fish: [
          "질병률이 높아지며 사람들은 참치캔을 대량으로 구입하게 되어 물고기가 멸종했습니다.",
        ],
        egg: [
          "달걀은 백신의 중요한 원료 중 하나입니다. 팬데믹으로 인하여 닭이 멸종합니다.",
          "닭이 멸종하게 되면서 벌레가 많아집니다.",
        ],
        meal: ["벌레가 많아지자 곡식이 모두 동나기 시작합니다."],
        meat: [
          "곡식이 모두 동나자 가축들이 멸종합니다.",
          "벌레가 많아지게 되면서 질병률이 상승합니다.",
        ],
      },
      vaccineLog: [
        "병원에서 발생하는 의료 폐기물을 소각하는 과정에서 환경 오염이 발생합니다. 또한 바이러스에 대한 공포로 일회용 플라스틱 소비가 늘어났습니다.",
      ],
      sizeAdjust: [
        "원활한 플레이를 위해 화면 크기를 최대한 키워주세요.",
        "화면을 최대한 키워도 시작화면이 보이지 않는다면,",
        "아래 단축키를 활용하여 화면 비율을 축소해 주세요.",
        `( 윈도우즈 계열 : Ctrl + "-" / 맥 OS 계열 : Command + "-" )`,
      ],
    },
    intro: {
      textLine: [
        "지구라는 우주선에는 승객이 없습니다. 우리는 모두 승무원입니다.",
        "- Marshall McLuhan -",
      ],
    },
    createHuman: {
      placeholder: ["이름을 입력해 주세요"],
    },
    playHuman: {
      age: (age: number): string => {
        return `${age}세`;
      },
      tooltip: {
        life: ["생명력"],
        temperature: ["온도"],
        disease: ["질병률"],
        vaccine: [
          "질병률이 70% 이상일때, 한 번만 사용할 수 있습니다",
          "백신을 이미 사용했습니다",
        ],
        extinct: {
          fish: "물고기가 멸종했습니다.",
          insect: "곤충이 멸종했습니다.",
          meat: "가축이 멸종했습니다.",
          meal: "식물이 멸종했습니다.",
          egg: "닭이 멸종했습니다.",
        },
      },
    },
    earth: {
      temperature: (temperature: number): string => {
        return `온도상승률 ${temperature.toFixed(2)}°`;
      },
      disease: (disease: number): string => {
        return `질병률 ${disease.toFixed(2)}%`;
      },
      generation: (generation: number): string => {
        return `${generation} 번째 지구`;
      },
    },
    ending: {
      temperature: {
        textLine: ["온도 상승엔딩", "온도가 6도 상승하여 멸종했습니다."],
      },
      disease: {
        textLine: ["질병률 상승엔딩", "질병률 100%에 도달하여 멸종했습니다."],
      },
      noLife: {
        textLine: ["생명력 저하엔딩", "생명력이 바닥났습니다."],
      },
      common: {
        record: (name: string, generation: number, age: number): string =>
          `${name}은(는) ${generation} 번째 지구에서 ${age}살까지 살아남았습니다.`,
        textLine: [
          "지구 평균 표면 온도는 지난 1만 2000년동안은 일정했지만",
          "산업혁명 이후 1850년에서 2019년 사이에 1.1°C 상승했습니다.",
        ],
      },
    },
  },
  en: {
    home: {
      extinctLog: {
        fish: [
          "The rising morbidity has forced people to buy canned tuna in bulk, causing the fish to become extinct.",
        ],
        egg: [
          "Eggs are one of the important raw materials for vaccines. Chickens are going extinct due to a pandemic.",
          "As chickens become extinct, the number of worms increases.",
        ],
        meal: ["As the number of insects increases, the crop runs out."],
        meat: [
          "When the crops run out, the livestock become extinct.",
          "As the number of insects increases, the morbidity rate rises.",
        ],
      },
      vaccineLog: [
        "Environmental pollution occurs in the process of incineration of medical waste generated in hospitals. Also, the fear of the virus has increased the consumption of single-use plastics.",
      ],
      sizeAdjust: [
        "Please increase the screen size as much as possible for smooth play.",
        "If you can't see the start screen even if you enlarge the screen as much as possible,",
        "please use the shortcut below to reduce the screen ratio.",
        `( Windows : Ctrl + "-" / Mac OS : Command + "-" )`,
      ],
    },
    intro: {
      textLine: [
        "There are no passengers on spaceship earth. We are all crew.",
        "- Marshall McLuhan -",
      ],
    },
    createHuman: {
      placeholder: ["Enter your name"],
    },
    playHuman: {
      age: (age: number): string => {
        return `${age} years old`;
      },
      tooltip: {
        life: ["LIFE "],
        temperature: ["TEMPERATURE"],
        disease: ["MORBIDITY"],
        vaccine: [
          "When the morbidity is above 70%, it can only be used once.",
          "You've already used the vaccine.",
        ],
        extinct: {
          fish: "Fish went extinct.",
          insect: "Insect went extinct.",
          meat: "Livestock went extinct.",
          meal: "Plants went extinct.",
          egg: "Chickens went extinct.",
        },
      },
    },
    earth: {
      temperature: (temperature: number): string => {
        return `${temperature.toFixed(2)}°C INCREASE`;
      },
      disease: (disease: number): string => {
        return `${disease.toFixed(2)}% MORBIDITY`;
      },
      generation: (generation: number): string => {
        if (generation === 1) {
          return "1st EARTH";
        } else if (generation === 2) {
          return "2nd EARTH";
        } else if (generation === 3) {
          return "3rd EARTH";
        } else {
          return generation + "th EARTH";
        }
      },
    },
    ending: {
      temperature: {
        textLine: [
          "End of temperature increase.",
          "The temperature rose by 6 degrees and went extinct.",
        ],
      },
      disease: {
        textLine: [
          "End of Morbidity Rise.",
          "Morbidity reached at 100% and perished.",
        ],
      },
      noLife: {
        textLine: ["End of life loss", "Life has vanished."],
      },
      common: {
        record: (name: string, generation: number, age: number): string => {
          if (generation === 1) {
            return `${name} has survived to the age of ${age} on the ${generation}st Earth.`;
          } else if (generation === 2) {
            return `${name} has survived to the age of ${age} on the ${generation}nd Earth.`;
          } else if (generation === 3) {
            return `${name} has survived to the age of ${age} on the ${generation}rd Earth.`;
          } else {
            return `${name} has survived to the age of ${age} on the ${generation}th Earth.`;
          }
        },
        textLine: [
          "Average global surface temperature has remained constant over the past 12,000 years,",
          "but has risen by 1.1 degrees Celsius between 1850 and 2019 after the Industrial Revolution.",
        ],
      },
    },
  },
};

export default text;
