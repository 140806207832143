import React from "react";
import styled from "styled-components";
import CreateHuman from "../../Components/CreateHuman";
import PlayHuman from "../../Components/PlayHuman";
import useInput from "../../Hooks/useInput";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
`;

interface Props {
  human: Human | undefined;
  createHuman: (name: string) => void;
  updateHuman: (delta: UpdatableHumanAttributes) => void;
  vaccinate: () => void;
  vaccineReady: boolean;
  disabledFoods: Array<Food>;
  gameOver: (causeOfDeath: CauseOfDeath) => void;
  enterBridge: boolean;
  seeEndingScene: () => void;
  updateEarth: (delta: UpdatableEarthAttributes) => void;
  addLog: (log: Log) => void;
  lan: Language;
}

const Human: React.FunctionComponent<Props> = ({
  human,
  createHuman,
  updateHuman,
  vaccinate,
  vaccineReady,
  disabledFoods,
  gameOver,
  enterBridge,
  seeEndingScene,
  updateEarth,
  addLog,
  lan,
}: Props) => {
  const inputName = useInput("");

  async function handleCreateHuman() {
    if (inputName.value !== "") {
      createHuman(inputName.value);
    }
  }

  return (
    <Wrapper>
      {human ? (
        <PlayHuman
          human={human}
          updateHuman={updateHuman}
          vaccinate={vaccinate}
          vaccineReady={vaccineReady}
          disabledFoods={disabledFoods}
          gameOver={gameOver}
          enterBridge={enterBridge}
          seeEndingScene={seeEndingScene}
          updateEarth={updateEarth}
          addLog={addLog}
          lan={lan}
        />
      ) : (
        <CreateHuman
          inputName={inputName}
          handleCreateHuman={handleCreateHuman}
          lan={lan}
        />
      )}
    </Wrapper>
  );
};

export default Human;
