import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import text from "../../text-data";
import Loader from "../Loader";

interface StyledProps {
  sceneEnter?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30vh;
`;

const Scene = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Loading = styled.div`
  font-size: 24px;
  margin: 16px 0px 36px 0px;
`;

const BlinkFrame = keyframes`
  0%{
    opacity: 0;
  }50%{
    opacity: 1;
  }100%{
    opacity: 0;
  }
`;

const Text = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${BlinkFrame} 1500ms ease-in-out infinite;
`;

const TextLine = styled.div`
  font-size: 24px;
  margin: 8px 0px;
`;

interface Props {
  changeStageTo: (nextStage: string) => void;
  lan: Language;
}

const Intro: React.FunctionComponent<Props> = ({
  changeStageTo,
  lan,
}: Props) => {
  const [sceneNo, setSceneNo] = useState<number>(-1);
  const [sceneEnter, setSceneEnter] = useState<boolean>(false);
  const sceneCount = 0;

  useEffect(() => {
    if (sceneNo > -1) {
      setTimeout(enterScene, 500);
    }
  }, [sceneNo]);

  useEffect(() => {
    if (sceneEnter) {
      setTimeout(exitScene, 8000);
    } else {
      setTimeout(nextScene, 500);
    }
  }, [sceneEnter]);

  function enterScene() {
    setSceneEnter(true);
  }

  function exitScene() {
    setSceneEnter(false);
  }

  function nextScene() {
    if (sceneNo < sceneCount) {
      setSceneNo((v) => v + 1);
    } else {
      changeStageTo("CREATE");
    }
  }

  return (
    <Wrapper>
      <Loader type="human" />
      <Loading>Loading</Loading>
      {sceneNo === 0 && (
        <Scene>
          <Text sceneEnter={sceneEnter}>
            <TextLine>{text[lan].intro.textLine[0]}</TextLine>
            <TextLine>{text[lan].intro.textLine[1]}</TextLine>
          </Text>
        </Scene>
      )}
    </Wrapper>
  );
};

export default Intro;
