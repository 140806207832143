import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Typed from "typed.js";

interface StyledProps {
  index?: number;
  shouldPop?: boolean;
  type?: LogType;
  isVaccine?: boolean;
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 48px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const fadeInFrames = (
  index: number,
  type: LogType,
  isVaccine: boolean
) => keyframes`
    0%{
      opacity: 1;
      border-color: ${index === 0 ? "#e6e6e6" : "white"};
      background-color: ${type === "user" ? "white" : "black"}
      color: ${index === 0 ? "#e6e6e6" : type === "user" ? "white" : "black"};
      transform: translate(${index * -5 - 5}px, ${index * -5 - 5}px);
    }100%{
      opacity: 1;
      border-color: ${index === 0 ? "black" : "#e6e6e6"};
      background-color: ${type === "user" ? "white" : "black"};
      color: ${
        index === 0
          ? type === "user"
            ? "black"
            : isVaccine
            ? "#ff0000"
            : "white"
          : "#e6e6e6"
      };
      transform: translate(${index * -5}px, ${index * -5}px);
    }
`;

const fadeOutFrames = keyframes`
    0%{
        opacity: 1;
        transform: translateY(0px);
    }50%{
        opacity: 0;
    }
    100%{
        opacity: 0;
        transform: translateY(200%);
    }
`;

const fadeInCSS = (index: number, type: LogType, isVaccine: boolean) => css`
  border: 2px solid ${index === 0 ? "black" : "#e6e6e6"};
  animation: ${fadeInFrames(index, type, isVaccine)} 0.3s ease-in forwards;
  z-index: ${20 - index};
`;

const fadeOutCSS = css`
  animation: ${fadeOutFrames} 0.5s ease-in forwards;
`;

const LogBox = styled.div<StyledProps>`
  position: absolute;
  width: 80vw;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  background-color: ${(props) =>
    props.type === "user" ? "#ffffff" : "#000000"};
  border: 2px solid black;
  border-radius: 20px;
  padding: 16px 36px;
  transition: background-color 0.2s ease-in;
  ${(props) =>
    props.index !== undefined &&
    props.type !== undefined &&
    props.isVaccine !== undefined &&
    fadeInCSS(props.index, props.type, props.isVaccine)}
  ${(props) => props.shouldPop && fadeOutCSS}
`;

interface Props {
  logQueue: Array<Log>;
  popLogQueue: () => void;
}

const MiniLog: React.FunctionComponent<Props> = ({
  logQueue,
  popLogQueue,
}: Props) => {
  const [shouldPop, setShouldPop] = useState<boolean>(false);
  const [floatingLog, setFloatingLog] = useState<boolean>(false);

  const wrapperRef = useRef<any>();
  const typedRef = useRef<any>();
  const globalT = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (logQueue.length > 1 && !shouldPop) {
      if (globalT.current) {
        clearTimeout(globalT.current);
      }
      setShouldPop(true);
    }
    return () => {
      if (globalT.current) {
        clearTimeout(globalT.current);
      }
    };
  }, [logQueue, shouldPop]);

  useEffect(() => {
    if (logQueue.length > 0 && !floatingLog && wrapperRef.current) {
      setFloatingLog(true);

      typedRef.current = new Typed(wrapperRef.current, {
        strings: [logQueue[0].content],
        typeSpeed: 40,
        showCursor: false,
        onComplete: () => {
          globalT.current = setTimeout(() => {
            setShouldPop(true);
          }, 3000);
        },
        onDestroy: popLogQueue,
      });
    }
    return () => {
      if (globalT.current) {
        clearTimeout(globalT.current);
      }
    };
  }, [logQueue, floatingLog]);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (shouldPop) {
      t = setTimeout(() => {
        typedRef.current.destroy();
        setShouldPop(false);
        setFloatingLog(false);
      }, 300);
    }
    return () => {
      if (t) {
        clearTimeout(t);
      }
      if (globalT.current) {
        clearTimeout(globalT.current);
      }
    };
  }, [shouldPop]);

  return (
    <Wrapper>
      {logQueue.map((log, idx) => {
        return (
          <LogBox
            key={idx}
            index={idx}
            type={log.type}
            isVaccine={log.delta === "vaccine"}
            shouldPop={idx === 0 ? shouldPop : false}
            ref={idx === 0 ? wrapperRef : null}
          />
        );
      })}
    </Wrapper>
  );
};
export default MiniLog;
