import React, { useState } from "react";
import useFirebase from "./useFirebase";

interface useHumanType {
  human: Human | null;
  clearHuman: () => void;
  createHumanData: (name: string, earthId: string) => void;
  createHumanDeathData: (id: string, cause: CauseOfDeath) => void;
}

export default (): useHumanType => {
  const firebase = useFirebase();
  const db = firebase.database();
  const [human, setHuman] = useState<Human | null>(null);

  async function createHumanData(name: string, earthId: string) {
    const id = db.ref("humans/").push().key;
    if (id) {
      const newHumanInfo: Human = {
        id: id,
        name: name,
        life: 100,
        earthId: earthId,
        birth: new Date().getTime(),
        death: 0,
        deathInfo: "ALIVE",
        vaccination: false,
      };
      await db.ref("humans/").child(id).set(newHumanInfo);
      getHuman(id);
    }
  }

  function createHumanDeathData(id: string, cause: CauseOfDeath) {
    db.ref(`humans/${id}/`).update({
      death: new Date().getTime(),
      deathInfo: cause,
    });
  }

  function getHuman(id: string) {
    db.ref(`humans/${id}/`).on("value", (snapshot) => {
      const human: Human = snapshot.val();
      if (human) {
        setHuman(human);
      }
    });
  }

  function clearHuman() {
    setHuman(null);
  }

  return {
    human,
    clearHuman,
    createHumanData,
    createHumanDeathData,
  };
};
