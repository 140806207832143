import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "styled-components";
import DeviceContext from "../../Context/DeviceContext";
import globalStaticVariables from "../../global-static-variables";
import GlobalStyle from "../../Styles/global-styles";
import theme from "../../Styles/theme";
import AppPresenter from "./AppPresenter";

const AppContainer: React.FunctionComponent = () => {
  const { bgScreen, mdScreen, smScreen } = globalStaticVariables.breakPoints;

  const [device, setDevice] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [needSizeAdjust, setNeedSizeAdjust] = useState<boolean>(true);

  const isBgScreen = useMediaQuery({
    query: `(min-width:${bgScreen}px)`,
  });
  const isMdScreen = useMediaQuery({
    query: `(min-width:${mdScreen}px) and (max-width:${bgScreen - 1}px)`,
  });
  const isTabletScreen = useMediaQuery({
    query: `(min-width:${smScreen}px) and (max-width:${mdScreen - 1}px)`,
  });
  const isMobileScreen = useMediaQuery({
    query: `(max-width:${smScreen}px)`,
  });

  useEffect(() => {
    function isPlayableScreen() {
      if (window.innerWidth >= 1600) {
        setNeedSizeAdjust(false);
      } else {
        setNeedSizeAdjust(true);
      }
    }
    isPlayableScreen();
    window.addEventListener("resize", isPlayableScreen);

    return () => window.removeEventListener("resize", isPlayableScreen);
  });

  useEffect(() => {
    function isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
    if (isMobile()) {
      setDevice("mobile");
    } else {
      if (isBgScreen) {
        setDevice("bgScreen");
      } else if (isMdScreen) {
        setDevice("mdScreen");
      } else if (isTabletScreen) {
        setDevice("tabletScreen");
      } else if (isMobileScreen) {
        setDevice("mobileScreen");
      }
    }
    setLoading(false);
  }, [isBgScreen, isMdScreen, isTabletScreen, isMobileScreen]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <DeviceContext.Provider value={{ device, loading, needSizeAdjust }}>
          <AppPresenter />
        </DeviceContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default AppContainer;
