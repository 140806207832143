import React from "react";
import InsectImg from "../Images/icons/bug.png";
import EggImg from "../Images/icons/chicken.png";
import FishImg from "../Images/icons/fish.png";
import HeartImg from "../Images/icons/heart.png";
import FoodImg from "../Images/icons/knife.png";
import LifeEmptyImg from "../Images/icons/lifeEmpty.png";
import LifeFullImg from "../Images/icons/lifeFull.png";
import LifeHalfImg from "../Images/icons/lifeHalf.png";
import LightImg from "../Images/icons/light.png";
import MealImg from "../Images/icons/meal.png";
import MeatImg from "../Images/icons/meat.png";
import VaccineImg from "../Images/icons/medical.png";

interface Props {
  size: number;
}

export const FoodIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={FoodImg} width={size} height={size} />
);

export const LightIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={LightImg} width={size} height={size} />
);

export const VaccineIcon: React.FunctionComponent<Props> = ({
  size,
}: Props) => <img src={VaccineImg} width={size} height={size} />;

export const MeatIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={MeatImg} width={size} height={size} />
);

export const EggIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={EggImg} width={size} height={size} />
);

export const MealIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={MealImg} width={size} height={size} />
);

export const FishIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={FishImg} width={size} height={size} />
);

export const InsectIcon: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={InsectImg} width={size} height={size} />
);

export const LifeFull: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={LifeFullImg} width={size} height={size} />
);

export const LifeHalf: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={LifeHalfImg} width={size} height={size} />
);

export const LifeEmpty: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={LifeEmptyImg} width={size} height={size} />
);

export const Heart: React.FunctionComponent<Props> = ({ size }: Props) => (
  <img src={HeartImg} width={size} height={size} />
);
