import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import death from "../../Images/death.png";
import walkSprite from "../../Images/walk_sprite.png";

interface StyledProps {
  isLightOn?: boolean;
  isNight?: boolean;
  pos?: {
    left: number;
    top: number;
  };
  enterBridge?: boolean;
}

const Wrapper = styled.div`
  width: 100px;
  height: 100px;
  z-index: 20;
`;

const ImageWrapper = styled.div<StyledProps>`
  position: absolute;
  ${(props) => props.pos && `top: ${props.pos.top}px`};
  ${(props) => props.pos && `left: ${props.pos.left}px`};
`;

const fadeOutOverlayFrame = keyframes`
    0%{
        background-color: rgba(255, 255, 255, 0);
    }60%{
        background-color: rgba(255, 255, 255, 1);
    }95%{
        background-color: rgba(255, 255, 255, 1);
    }100%{
        background-color: rgba(0, 0, 0, 1);
    }
`;

const fadeOutOverlay = (second: number, delay: number) => css`
  animation: ${fadeOutOverlayFrame} ${second}s ease-in ${delay}s forwards;
`;

const fadeOutImageFrame = keyframes`
    0%{
        opacity: 1;
    }100%{
        opacity: 0;
    }
`;

const fadeOutImage = (second: number, delay: number) => css`
  animation: ${fadeOutImageFrame} ${second}s ease-in ${delay}s forwards;
`;

const Image = styled.img<StyledProps>`
  width: 100px;
  height: 100px;
  object-fit: cover;
  transition: filter 0.5s ease-in-out;
  ${(props) => props.enterBridge && fadeOutImage(3, 10)};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0);
  ${fadeOutOverlay(14, 0)};
  z-index: 20;
`;

interface Props {
  enterBridge: boolean;
  seeEndingScene: () => void;
  isLightOn: boolean;
  isNight: boolean;
}

const HumanImage: React.FunctionComponent<Props> = ({
  enterBridge,
  seeEndingScene,
  isLightOn,
  isNight,
}: Props) => {
  const [stripePos, setStripePos] = useState<number>(0);
  const [imagePos, setImagePos] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  const [delay, setDelay] = useState<number>(100);
  const tDelay = useRef<any>();
  const imageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (enterBridge && imageRef.current) {
      window.scrollTo({ top: 0 });
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        document.body.style.overflow = "";
      }, 15000);
      const { x, y } = imageRef.current.getBoundingClientRect();
      setImagePos({ left: x, top: y });
      tDelay.current = setInterval(() => setDelay((v) => v + 50), 1000);
    }
    return () => clearInterval(tDelay.current);
  }, [enterBridge]);

  useEffect(() => {
    // 14초 후에 엔딩으로
    if (delay >= 800) {
      clearInterval(tDelay.current);
      seeEndingScene();
    }
  }, [delay]);

  useEffect(() => {
    const t = setTimeout(() => {
      setStripePos((v) => (v - 100) % 400);
    }, delay);
    return () => clearTimeout(t);
  }, [stripePos, delay]);

  useEffect(() => {
    function handleResize() {
      if (imageRef.current) {
        const { x, y } = imageRef.current.getBoundingClientRect();
        setImagePos({ left: x, top: y });
      } else {
        setImagePos({ left: 0, top: 0 });
      }
    }
    if (imageRef.current) {
      const { x, y } = imageRef.current.getBoundingClientRect();
      setImagePos({ left: x, top: y });
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [imageRef]);

  return (
    <Wrapper ref={imageRef}>
      {enterBridge ? (
        <Overlay>
          <ImageWrapper pos={imagePos}>
            <Image
              width={100}
              height={100}
              style={{
                objectPosition: `${delay >= 600 ? 0 : stripePos}px 0px`,
              }}
              src={delay >= 600 ? death : walkSprite}
              isLightOn={isLightOn}
              isNight={isNight}
              enterBridge={enterBridge}
            />
          </ImageWrapper>
        </Overlay>
      ) : (
        <Image
          width={100}
          height={100}
          style={{ objectPosition: `${delay >= 600 ? 0 : stripePos}px 0px` }}
          src={delay >= 600 ? death : walkSprite}
          isLightOn={isLightOn}
        />
      )}
    </Wrapper>
  );
};

export default HumanImage;
