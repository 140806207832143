import React, { useEffect, useState } from "react";
import useFirebase from "./useFirebase";

interface useSettingsType {
  settings: Settings | undefined;
  updateSettings: (ref: string, updateObj: any) => void;
  addMessage: (payload: Message) => void;
  removeMessage: (id: string) => void;
  messages: Array<Message> | undefined;
}

export default (): useSettingsType => {
  const firebase = useFirebase();
  const db = firebase.database();

  const [settings, setSettings] = useState<Settings>();
  const [messages, setMessages] = useState<Array<Message>>();

  useEffect(() => {
    getSettings();
    getMessages();
  }, []);

  async function getSettings() {
    db.ref("settings/").on("value", (snapshot) => {
      const settings: Settings = snapshot.val();
      if (settings) {
        setSettings(settings);
      }
    });
  }

  async function updateSettings(ref: string, updateObj: any) {
    db.ref(`settings/${ref}`).update(updateObj);
  }

  async function getMessages() {
    db.ref("settings/messages_lan/").on("value", (snapshots) => {
      const messages: Array<Message> = [];
      snapshots.forEach((snapshot) => {
        messages.push(snapshot.val());
      });
      if (messages) {
        setMessages(messages);
      }
    });
  }

  async function addMessage(payload: Message) {
    const id = db.ref(`settings/messages_lan`).push().key;
    if (id) {
      await db.ref("settings/messages_lan").child(id).set(payload);
    }
  }

  async function removeMessage(id: string) {
    await db.ref(`settings/messages_lan/${id}`).remove();
  }

  return { settings, updateSettings, messages, addMessage, removeMessage };
};
