import React, { useEffect, useState } from "react";
import useFirebase from "./useFirebase";

interface useEarthType {
  earth: Earth | undefined;
  restartEarth: () => void;
  changeTemperature: (value: number) => void;
  changeDisease: (value: number) => void;
}

export default (): useEarthType => {
  const firebase = useFirebase();
  const db = firebase.database();
  const [earth, setEarth] = useState<Earth>();

  useEffect(() => {
    getEarth();
  }, []);

  async function getEarth() {
    db.ref("earth/")
      .limitToLast(1)
      .on("child_added", (snapshot) => {
        const earth: Earth = snapshot.val();
        setEarth(earth);
      });
  }

  async function restartEarth() {
    if (earth) {
      db.ref(`earth/${earth.id}/`).update({
        death: new Date().getTime(),
      });

      const newId = db.ref("earth/").push().key;
      if (newId) {
        const newEarth: Earth = {
          id: newId,
          generation: earth.generation + 1,
          temperature: 1.1,
          disease: 30,
          birth: new Date().getTime(),
          death: 0,
        };

        await db.ref("earth/").child(newId).set(newEarth);
      }
    }
  }

  function changeTemperature(value: number) {
    if (earth) {
      db.ref(`earth/${earth.id}/temperature`).transaction((cur) => {
        return Number(value.toFixed(2));
      });
    }
  }

  function changeDisease(value: number) {
    if (earth) {
      db.ref(`earth/${earth.id}/disease`).transaction((cur) => {
        return Number(value.toFixed(2));
      });
    }
  }

  return {
    earth,
    restartEarth,
    changeTemperature,
    changeDisease,
  };
};
