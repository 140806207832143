import React from "react";
import styled from "styled-components";
import HumanImg from "../../Images/evolvewalk.gif";
import LoaderImg from "../../Images/loader.gif";

interface StyledProps {
  size?: number;
}

const Wrapper = styled.div<StyledProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

interface Props {
  type?: string;
  size?: number;
}

const Loader: React.FunctionComponent<Props> = ({
  type = "default",
  size = 100,
}: Props) => {
  return (
    <Wrapper size={size}>
      {type === "default" && <img src={LoaderImg} />}
      {type === "human" && <img src={HumanImg} />}
    </Wrapper>
  );
};
export default Loader;
